/*=========================================================================================
  File Name: auth.services.js
  Description: Authentication Services
  ----------------------------------------------------------------------------------------
  Item Name: A.R.T Web Frame
  Version: 1.0.0
  Author: Asheori Reactive Technology
  Author URL: https://asheori.com
==========================================================================================*/

import * as axios from 'axios';
import {
  store
} from "./service.store";
import {
  HTTPAUTH,
  HTTPREG
} from "./http.common";

export const AUTH = {

  authenticateUser: (payload) => {
    return new Promise((resolve, reject) => {
      HTTPAUTH.post(store.api.config.AUTHENTICATE_USER, payload)
        .then(authResponse => {
          resolve(authResponse.data);
        }).catch(authError => {
          reject(authError);
        });
    });
  },

  verifyUser: (payload) => {
    const HTTPX = axios.create({
      baseURL: `${store.api.config.registration_endpoint}/`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': store.api.config.DEFAULT_AUTHENTICATION_TOKEN,
        'token': payload.token,
        'email': payload.email,
        'mobile': payload.mobile
      }
    });
    return new Promise((resolve, reject) => {
      HTTPX.post(store.api.config.VERIFY_USER, {})
        .then(verificationResponse => {
          resolve(verificationResponse.data);
        }).catch(verificationError => {
          reject(verificationError);
        });
    });
  },

  getRegistrationAccessToken: () => {
    return new Promise((resolve, reject) => {
      HTTPREG
        .post(`${store.api.config.registration_endpoint}/${store.api.config.GET_TOKEN}`, {})
        .then(
          accessTokenResponse => {
            resolve(accessTokenResponse.data);
          }).catch(
          accessTokenError => {
            reject(accessTokenError);
          }
        );
    });
  },

  getAuthAcessToken: () => {
    return new Promise((resolve, reject) => {
      HTTPAUTH
        .post(store.api.config.GET_TOKEN, {})
        .then(
          accessTokenResponse => {
            resolve(accessTokenResponse.data);
          }).catch(accessTokenError => {
          reject(accessTokenError);
        });
    });
  },
}