<template>
  <f7-page name="wallet" ptr @ptr:refresh="refresh">
    <f7-progressbar v-if="loading" infinite color="multi"></f7-progressbar>
    <f7-navbar>
      <f7-nav-title>My Bookings</f7-nav-title>
    </f7-navbar>
    <!-- No Bookings -->
    <div v-if="UserBookings.length === 0" class="p-20 text-center mt-40">
      <img :src="staticImage" alt=":-(" class="asset-img" />
      <div class="p-20 text-center text-white">No booking available.</div>
    </div>

    <!-- Bookings -->
    <f7-card expandable v-for="(item, index) in UserBookings" :key="index">
      <f7-card-content :padding="false">
        <div
          :class="item.confirm_status ? 'bg-color-grey' : 'bg-primary'"
          :style="{height: '300px'}"
        >
          <f7-card-header text-color="white" class="display-block">
            <img
              :src="item.provider.photo.url ? item.provider.photo.url : defaultAvatar"
              class="booking-avatar"
            />
            <br />
            {{ item.provider.firstname }}
            <br />
            <f7-icon f7="calendar_today" size="22px" color="black"></f7-icon>&nbsp;
            <span
              class="text-black"
            >{{ (new Date(item.booking.booking_date)).toDateString() }}</span>
            <br />
            <small class="op-70 small-text">
              <f7-icon f7="time" size="18px" color="black"></f7-icon>&nbsp;
              <span class="text-black">{{ item.booking.booking_time }}</span>
            </small>
            <!-- <br>
            <small class="op-70 small-text">
              <f7-icon f7="timer" size="18px" color="white"></f7-icon>
              &nbsp;<timeago :datetime="item.booking.booking_date"></timeago>
            </small>-->
          </f7-card-header>
          <f7-link
            card-close
            color="white"
            class="card-opened-fade-in"
            :style="{position: 'absolute', right: '15px', top: '15px'}"
            icon-f7="close_round_fill"
          ></f7-link>
        </div>
        <div v-if="subscriptionValid" class="card-content-padding">
          <f7-block v-if="!item.confirm_status" class="mb-20 mt-10">
            <f7-icon size="20px" color="pink" f7="phone"></f7-icon>
            &nbsp;{{ item.provider.mobile ? item.provider.mobile : 'Undisclosed' }}
          </f7-block>
          <f7-block class="mb-20">
            <f7-icon size="20px" color="blue" f7="placemark"></f7-icon>
            &nbsp;{{ item.provider.city ? item.provider.city : 'Undisclosed' }}
          </f7-block>
          <f7-block class="mb-10" v-if="item.provider.interests.length > 0">
            <f7-icon size="20px" color="purple" f7="heart"></f7-icon>
            &nbsp;{{ item.provider.interests.join(', ') }}
          </f7-block>
          <f7-block-title class="blockTitle">About {{ item.provider.firstname }}</f7-block-title>
          <f7-block class="mb-10">
            <p>{{ item.provider.about ? item.provider.about : `No information available for ${item.provider.firstname}` }}</p>
          </f7-block>
          <f7-block class="mt-20 mb-20" v-if="!item.confirm_status">
            <f7-row>
              <f7-col>
                <f7-button @click="callNumber(item.provider.mobile)" fill round large card-close>
                  <f7-icon f7="phone" color="white"></f7-icon>&nbsp;Call
                </f7-button>
              </f7-col>
              <f7-col>
                <f7-button @click="confirmService(item)" color="green" fill round large card-close>
                  <f7-icon f7="check" color="white"></f7-icon>&nbsp;Confirm
                </f7-button>
              </f7-col>
            </f7-row>
          </f7-block>
          <f7-block-title class="blockTitle">Massage Service Booked</f7-block-title>
          <f7-block strong class="mb-10">
            <f7-chip
              class="m-3"
              :text="item.booking.service ? item.booking.service : 'Unspecified'"
              :media-bg-color="utils.bgColor(item.booking.service ? item.booking.service : 'Unspecified')"
              :media="(item.booking.service ? item.booking.service : 'Unspecified').substring(0,1)"
            ></f7-chip>
          </f7-block>
          <f7-card
            v-for="(image, index) in item.provider.gallery"
            :key="index"
            :style="{borderRadius: '10px', marginBottom: '15px'}"
          >
            <div
              :style="{background: `url(${image.photo.url}) no-repeat center top`, 'background-size': 'cover', height: '480px', borderRadius: '10px'}"
            ></div>
          </f7-card>
        </div>
        <div v-if="!subscriptionValid" class="card-content-padding">
          <div class="p-20 text-center mt-40">
            <img :src="staticImage" alt=":-(" class="asset-img" />
            <div class="p-20 text-center text-white">Please subscribe to view booking details.</div>
          </div>
        </div>
        <f7-block class="mt-10 mb-10" v-if="!item.confirm_status">
          <p>
            <f7-button @click="cancelBooking(item)" color="red" fill round large card-close>
              <f7-icon f7="trash" size="24px" color="white"></f7-icon>&nbsp;Cancel Booking
            </f7-button>
          </p>
        </f7-block>
        <f7-block class="mt-10 mb-10" v-if="item.confirm_status">
          <f7-button @click="burnBooking(item)" color="red" fill round large card-close>
            <f7-icon f7="fire" color="white"></f7-icon>&nbsp;Burn Booking
          </f7-button>
        </f7-block>
      </f7-card-content>
    </f7-card>
  </f7-page>
</template>
<script>
import { mapGetters } from "vuex";
import { utils } from "@/services/helper";
import Vue from "vue";
export default {
  data() {
    return {
      loading: false,
      utils: utils,
      payload: {},
      staticImage: require("@/assets/icons/door.png"),
      defaultAvatar: require("@/assets/icons/add-user.png")
    };
  },
  methods: {
    refresh(event, done) {
      this.loading = true;
      // Update Providers State
      this.$store.dispatch("getUserBookings", {
        data: this.candidateProfile.personal.identifier
      });

      setTimeout(() => {
        done();
        this.loading = false;
      }, 500);
    },

    callNumber(phone) {
       Vue.cordova.on("deviceready", () => {
        const cordovaCall = cordova.plugins.CordovaCall;
        cordovaCall.callNumber(
          phone,
          onSuccess => {},
          onError => {
            this.localNotify(
              "Call Error",
              `Unable to make this call, please try again. Error: ${onError}`,
              "error-theme"
            );
          }
        );
      });
    },

    confirmService(booking) {
      const app = this.$f7;
      app.dialog.confirm(
        `Are you sure you want to confirm that this service has been delivered by ${booking.provider.firstname}?`,
        () => {
          this.$store
            .dispatch("confirmBooking", {
              data: booking
            })
            .then(() => {
              // Response
              this.localNotify(
                "Confirmed Successfully!",
                `Service delivery by ${booking.provider.firstname} was successfully confirmed`,
                "success-theme"
              );
            })
            .catch(err => {
              this.localNotify(
                "Confirmation Error",
                `Unable to confirm this service, please try again. Error: ${err}`,
                "error-theme"
              );
            });
        }
      );
    },

    cancelBooking(booking) {
      const app = this.$f7;
      app.dialog.confirm(
        "Are you sure you want to cancel this booking?",
        () => {
          this.$store
            .dispatch("burnBooking", {
              data: booking
            })
            .then(() => {
              // Response
              this.localNotify(
                "Cancelled Successfully!",
                `Your booking with ${booking.provider.firstname} was successfully cancelled`,
                "success-theme"
              );
            })
            .catch(err => {
              this.localNotify(
                "Canellation Error",
                `Unable to cancel this booking, please try again. Error: ${err}`,
                "error-theme"
              );
            });
        }
      );
    },

    burnBooking(booking) {
      const app = this.$f7;
      app.dialog.confirm(
        "Are you sure you want to delete this booking?",
        () => {
          this.$store
            .dispatch("burnBooking", {
              data: booking
            })
            .then(() => {
              // Response
              this.localNotify(
                "Deleted Successfully!",
                `Your booking with ${booking.provider.firstname} was successfully deleted`,
                "success-theme"
              );
            })
            .catch(err => {
              this.localNotify(
                "Delete Error",
                `Unable to delete this booking, please try again. Error: ${err}`,
                "error-theme"
              );
            });
        }
      );
    },

    subscriptionValid() {
      const today = new Date();
      const endDate = new Date(this.userSubscription.end_date);
      if (endDate > today) {
        return true;
      } else {
        return false;
      }
    },

    localNotify(subtitle, text, colorTheme) {
      const self = this;
      const app = self.$f7;
      app.data.notification.show(app, subtitle, text, colorTheme);
    }
  },
  computed: {
    ...mapGetters(["candidateProfile", "UserBookings", "userSubscription"])
  }
};
</script>