    export const store = {
        api: {
            // authorization: JSON.parse(localStorage.getItem('relaxe_auth')) || { token: '', identity: ''},
            config: {
                
                authentication_endpoint: 'https://relaxe.auth.luxuryetal.com',
                registration_endpoint: 'https://relaxe.identity.luxuryetal.com',
                ledger_endpoint: 'https://relaxe.ledger.luxuryetal.com',
                data_endpoint: 'https://relaxe.data.luxuryetal.com',
                public_endpoint: 'https://relaxe.public.luxuryetal.com',

                DEFAULT_AUTHENTICATION_TOKEN: 'QBHtQdOP63jqP7bysGrB9N1sBZdmVh8H5RdOQXzdw',
                AUTHENTICATE_USER: 'authenticate/user',
                REGISTER_USER: 'register/user',
                REGISTER_QUICK_USER: 'register/quick/user',

                GENERATE_INVOICE: 'plan/payment/log',

                GET_PRICING: 'subscription/pricing/read',
                GET_USER_SUBSCRIPTION: 'subscription/user',

                GET_PROVIDERS: 'users/by/providers',
                FILTER_PROVIDERS: 'users/by/filter',
                CREATE_BOOKING: 'booking/create',
                GET_USER_BOOKING: 'booking/read/user',
                GET_PROVIDER_BOOKING: 'booking/read/provider',
                UPDATE_BOOKING: 'booking/update',
                CONFIRM_BOOKING: 'booking/confirm',
                BURN_BOOKING: 'booking/burn',
                CHECKIN_BOOKING: 'booking/checkin', 
                CHECKOUT_BOOKING: 'booking/checkout', 

                GET_GALLERY: 'gallery/get',
                ADD_GALLERY: 'gallery/add',
                SET_AS_AVATAR: 'gallery/set/avatar',
                REMOVE_GALLERY: 'gallery/remove',

                TRANSFER_FUND: 'transfer/fund',
                WALLET_BALANCE: 'ledger/balance',
                WALLET_TRANSACTION: 'user/ledger/log',
                FUND_WALLET: 'ledger/credit',
                GET_INVOICES: 'user/invoices/log',
                GET_PLANS: 'business/plans/read',
                GET_BENEFACTOR: 'user/account',

                UPDATE_AVATAR: 'user/avatar/update',
                UPDATE_USER_PHOTO: 'user/photo',
                UPDATE_GENERAL_SETTINGS: 'user/general/settings/update',
                UPDATE_PROVIDER_STATUS: 'user/provider/status/update',
                UPDATE_ONLINE_STATUS: 'user/online/status/update',
                UPDATE_NOTIFICATION_SETTINGS: 'user/notification/update',
                UPDATE_INTERESTS: 'user/interests/update',
                UPDATE_PREFERENCES: 'user/preferences/update',
                UPDATE_BRANDS: 'user/brands/update',
                UPDATE_REFERENCES: 'user/references/update',
                UPDATE_FINANCIAL: 'user/financial/update',
                UPDATE_PERSONAL: 'user/personal/update',
                UPDATE_PUBLIC: 'user/public/update',
                UPDATE_LOCATION: 'user/location/update',
                UPDATE_SECURITY: 'user/security/update',
                RESET_SECURITY: 'user/security/reset',

                UPDATE_VERIFY_EMAIL: 'user/verify/email/update',
                UPDATE_VERIFY_PHONE: 'user/verify/mobile/update',

                REMOVE_REFERENCES: 'user/references/remove',

                SEND_SUPPORT: 'support/create',
                VERIFY_USER: 'verify/user',
                VERIFY_EMAIL_PIN: 'verify/email/pin',
                VERIFY_MOBILE_PIN: 'verify/mobile/pin',
                VERIFY_PAYMENT: 'finance/paystack/verify',
                GET_TOKEN: 'access/init'
            }
        }
    }