import * as axios from "axios";
import {
    store
} from "./service.store";

export const HTTPAUTH = axios.create({
    baseURL: `${store.api.config.authentication_endpoint}/`,
    headers: {
        'Content-Type': 'application/json',
        'Authorization': store.api.config.DEFAULT_AUTHENTICATION_TOKEN
    }
});

export const HTTPREG = axios.create({
    baseURL: `${store.api.config.registration_endpoint}/`,
    headers: {
        'Content-Type': 'application/json',
        'Authorization': store.api.config.DEFAULT_AUTHENTICATION_TOKEN
    }
});
