<template>
  <div>
    <home-view v-if="!isProvider"></home-view>
    <appointments-view v-if="isProvider"></appointments-view>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["isProvider"])
  },
  mounted() {
    window.sessionStorage.setItem("last_page", "home")
  }
};
</script>