<template>
  <f7-app :params="f7params" theme-dark>
    <!-- Status bar overlay for fullscreen mode-->
    <f7-statusbar class="bg-dark"></f7-statusbar>

    <!-- Views/Tabs container -->
    <f7-views tabs class="safe-areas">
      <!-- Tabbar for switching views-tabs -->
      <f7-toolbar tabbar labels bottom>
        <f7-link
          v-if="!isProvider"
          tab-link="#view-home"
          tab-link-active
          icon-ios="f7:persons_round"
          icon-aurora="f7:persons_round"
          icon-md="f7:persons_round"
          text="Providers"
        ></f7-link>
        <f7-link
          v-if="!isProvider"
          tab-link="#view-booking"
          icon-ios="f7:calendar_today"
          icon-aurora="f7:calendar_today"
          icon-md="f7:calendar_todayt"
          text="Bookings"
          :icon-badge="unUsedBookings > 0 ? unUsedBookings : ''"
          badge-color="red"
        ></f7-link>
        <f7-link
          v-if="isProvider"
          tab-link="#view-home"
          tab-link-active
          icon-ios="f7:calendar_today"
          icon-aurora="f7:calendar_today"
          icon-md="f7:calendar_todayt"
          text="Bookings"
          :icon-badge="unUsedBookingsPro > 0 ? unUsedBookingsPro : ''"
          badge-color="red"
        ></f7-link>
        <f7-link
          v-if="isProvider"
          tab-link="#view-gallery"
          icon-ios="f7:images"
          icon-aurora="f7:images"
          icon-md="f7:images"
          text="Gallery"
        ></f7-link>
        <!-- <f7-link tab-link="#view-messaging" icon-ios="f7:chat" icon-aurora="f7:chat" icon-md="material:chat" text="Conversations"></f7-link> -->
        <f7-link
          tab-link="#view-wallet"
          icon-ios="f7:card_fill"
          icon-aurora="f7:card_fill"
          icon-md="f7:card_fill"
          text="Subscription"
        ></f7-link>
        <f7-link
          tab-link="#view-settings"
          icon-ios="f7:person"
          icon-aurora="f7:person"
          icon-md="f7:person"
          text="Profile"
        ></f7-link>
      </f7-toolbar>

      <!-- Your main view/tab, should have "view-main" class. It also has "tab-active" class -->
      <f7-view id="view-home" main tab tab-active url="/"></f7-view>

      <!-- Booking View -->
      <f7-view id="view-booking" name="booking" tab url="/bookings/"></f7-view>

      <!-- Appointments View -->
      <f7-view id="view-appointment" name="appointments" tab url="/"></f7-view>

      <!-- Gallery View -->
      <f7-view id="view-gallery" name="gallery" tab url="/gallery/"></f7-view>

      <!-- Messaging View -->
      <!-- <f7-view id="view-messaging" name="messaging" tab url="/messaging/"></f7-view> -->

      <!-- Wallet View -->
      <f7-view id="view-wallet" name="wallet" tab url="/wallet/"></f7-view>

      <!-- Settings View -->
      <f7-view id="view-settings" name="settings" tab url="/settings/"></f7-view>
    </f7-views>

    <!-- Right panel with reveal effect-->
    <f7-panel right reveal>
      <f7-view>
        <f7-progressbar v-if="loading" infinite color="multi"></f7-progressbar>
        <f7-page>
          <f7-navbar title="Provider Search Filter"></f7-navbar>
          <f7-block-title class="text-center text-primary">Location</f7-block-title>
          <f7-card class="curved">
            <f7-list no-hairlines-md>
              <f7-list-item
                v-for="(location, index) in locations"
                :key="index"
                @change="payload.city = $event.target.value"
                radio
                name="location"
                :checked="payload.city == location"
                :value="location"
                :title="location"
              ></f7-list-item>
            </f7-list>
          </f7-card>

          <f7-block-title class="text-center text-primary">Gender</f7-block-title>
          <f7-card class="curved">
            <f7-list class="curved" no-hairlines-md>
              <f7-list-item
                value="Female"
                :checked="payload.gender === 'Female'"
                @change="payload.gender = $event.target.value"
                radio
                name="gender"
                title="Female"
              ></f7-list-item>
              <f7-list-item
                value="Male"
                :checked="payload.gender === 'Male'"
                @change="payload.gender = $event.target.value"
                radio
                name="gender"
                title="Male"
              ></f7-list-item>
            </f7-list>
          </f7-card>
          <f7-block-title class="text-center text-primary">Massage Preferences</f7-block-title>
          <f7-card class="curved">
            <f7-list>
              <f7-list-item
                v-for="(preference, index) in preferences"
                :key="index"
                @change="checkPreferences"
                checkbox
                :checked="payload.preferences.indexOf(preference) >= 0"
                name="preferences"
                :title="preference"
                :value="preference"
              ></f7-list-item>
            </f7-list>
          </f7-card>
          <transition name="dropdown">
            <f7-block v-if="!loading">
              <f7-button class="col" large round fill raised @click="filterProviders">Apply Filter</f7-button>
            </f7-block>
          </transition>
        </f7-page>
      </f7-view>
    </f7-panel>
  </f7-app>
</template>
<script>
import routes from "@/js/routes.js";
import { NOTIFICATION } from "@/shared/notification";

export default {
  data() {
    return {
      // Filter Component Data
      loading: false,
      preferences: [
        "Chair",
        "Couples",
        "Deep Tissue",
        "Nuru",
        "Prenatal",
        "Swedish"
      ],
      locations: ["Lagos", "Abuja", "Port-Harcourt", "Calabar"],
      payload: {
        gender: "",
        city: "",
        preferences: []
      },
      // Framework7 Parameters
      f7params: {
        id: "com.luxuryetal.relaxe", // App bundle ID
        name: "Relaxé", // App name
        theme: "auto", // Automatic theme detection
        // App root data
        data: function() {
          return {
            notification: NOTIFICATION
          };
        },

        // App routes
        routes: routes,

      }
    };
  },
  methods: {
    filterProviders() {
      const self = this;
      self.loading = true;
      this.$store
        .dispatch("getFilteredProviders", {
          data: self.payload
        })
        .then(() => {
          // Response
          const app = self.$f7;
          app.panel.close();
          self.loading = false;
          /* self.payload = {
            gender: "",
            city: "",
            preferences: []
          }; */
        })
        .catch(err => {
          this.loading = false;
          this.localNotify(
            "Filter Error",
            `Unable to filter available providers, please try again. Error: ${err}`,
            "error-theme"
          );
        });
    },
    checkPreferences(event) {
      const self = this;
      const value = event.target.value;
      if (event.target.checked) {
        self.payload.preferences.push(value);
      } else {
        self.payload.preferences.splice(
          self.payload.preferences.indexOf(value),
          1
        );
      }
    },
    localNotify(subtitle, text, colorTheme) {
      const self = this;
      const app = self.$f7;
      app.data.notification.show(app, subtitle, text, colorTheme);
    }
  },
  mounted() {
    this.$f7ready(f7 => {
      // Init cordova APIs (see cordova-app.js)
      if (f7.device.cordova) {
        cordovaApp.init(f7);
      }
      // Call F7 APIs here
    });
  },
  computed: {
    unUsedBookings() {
      return this.$store.getters.UnusedUserBookings;
    },
    unUsedBookingsPro() {
      return this.$store.getters.UnusedProviderBookings;
    },
    isProvider() {
      return this.$store.getters.isProvider;
    }
  }
};
</script>