<template>
  <f7-page no-toolbar login-screen>
    <f7-navbar back-link="Back"></f7-navbar>
    <transition name="dropdown">
      <div class="text-center mt-20">
        <img class="logo" :src="staticLogo" />
      </div>
    </transition>
    <transition name="dropdown">
      <div
        class="text-center ml-10 mr-10"
      >The fastest growing personal massage provider<br/>booking platform in Nigeria.</div>
    </transition>
    <transition name="dropdown">
      <f7-list no-hairlines-md form>
        <f7-list-input
          outline
          floating-label
          label="Fullname"
          type="text"
          error-message="Fullname is required!"
          required
          validate-on-blur
          @input="payload.fullname = $event.target.value"
        ></f7-list-input>

        <f7-list-input
          @input="payload.email = $event.target.value"
          outline
          floating-label
          label="E-mail"
          type="email"
          required
          validate-on-blur
        ></f7-list-input>

        <f7-list-input
          outline
          floating-label
          label="Mobile Phone"
          type="tel"
          error-message="Please provide a valid Nigerian mobile phone number!"
          @input="payload.mobile = $event.target.value"
          required
          validate-on-blur
          pattern="^[0-9]{11}"
        ></f7-list-input>
        <f7-card class="curved">
          <f7-list class="p-10">
            <small class="text-primary ml-5">I AM SIGNING UP AS A...</small>
            <f7-list-item
              value="true"
              @change="payload.provider = $event.target.value"
              radio
              name="provider"
              title="Provider"
            ></f7-list-item>
            <f7-list-item
              @change="payload.gender = $event.target.value"
              radio
              name="provider"
              value="false"
              title="User"
            ></f7-list-item>
          </f7-list>
        </f7-card>
        <f7-card class="curved">
          <f7-list class="p-10">
            <small class="text-primary ml-5">I IDENTIFY AS...</small>

            <f7-list-item
              value="Female"
              @change="payload.gender = $event.target.value"
              radio
              name="gender"
              title="Female"
            ></f7-list-item>
            <f7-list-item
              @change="payload.gender = $event.target.value"
              radio
              name="gender"
              value="Male"
              title="Male"
            ></f7-list-item>
          </f7-list>
        </f7-card>
      </f7-list>
    </transition>
    <f7-progressbar v-if="loading" infinite color="multi"></f7-progressbar>
    <transition name="dropdown">
      <f7-block v-if="!loading">
        <f7-button large round fill raised @click="submitRegistration">Create Account</f7-button>
      </f7-block>
    </transition>
    <transition name="dropdown">
      <f7-block v-if="!loading" class="text-center">
        By tapping "Create Account" above, you agree to our
        <f7-link @click="openLegalPopup('terms')">Terms and Conditions</f7-link>&nbsp;and
        <f7-link @click="openLegalPopup('privacy')">Privacy Policy</f7-link>.
      </f7-block>
    </transition>
    <transition name="dropdown">
      <f7-list v-if="!loading">
        <f7-list-button @click="goBack">Got an account? Sign in</f7-list-button>
      </f7-list>
    </transition>
    <!-- Terms & Conditions Popup-->
    <f7-popup class="legal-popup" :opened="popupOpened" @popup:closed="popupOpened = false">
      <f7-page>
        <f7-navbar>
          <f7-nav-title sliding>{{ legal.title }}</f7-nav-title>
          <f7-nav-right>
            <f7-link popup-close>Cancel</f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-block>
          <div v-html="legal.content"></div>
        </f7-block>
      </f7-page>
    </f7-popup>
  </f7-page>
</template>
<script>
import Vue from "vue";
import { AUTH } from "@/services/auth.service";
import { REG } from "@/services/registration.service";
import { LEGAL } from "@/services/legal.service";
export default {
  data() {
    return {
      loading: false,
      popupOpened: false,
      legal: {
        title: "",
        content: ""
      },
      payload: {
        fullname: "",
        firstname: "",
        lastname: "",
        email: "",
        mobile: "",
        gender: "",
        token: "",
        agreeToTerms: true,
        provider: false,
        device: {}
      },
      staticLogo: require("@/assets/icons/relaxe.png")
    };
  },
  methods: {
    submitRegistration() {
      if (
        this.payload.fullname &&
        this.payload.email &&
        this.payload.mobile &&
        this.payload.gender
      ) {
        const fullname = this.payload.fullname.split(" ");
        this.payload.firstname =
          fullname[0].charAt(0).toUpperCase() + fullname[0].substring(1);
        this.payload.lastname = fullname[1]
          ? fullname[1].charAt(0).toUpperCase() + fullname[1].substring(1)
          : "";
        if (this.payload.lastname) {
          this.loading = true;
          this.getAccessToken();
        } else {
          this.loading = false;
          this.localNotify(
            "Information Error",
            `Please provide your fullname!`,
            "error-theme"
          );
        }
      } else {
        this.localNotify(
          "Information Error",
          `Please provide all information`,
          "error-theme"
        );
      }
    },
    getAccessToken() {
      AUTH.getRegistrationAccessToken()
        .then(accessTokenResponse => {
          this.payload.token = accessTokenResponse["result"]["token"];
          this.validateRegistration(this.payload);
        })
        .catch(accessTokenError => {
          this.loading = false;
          this.payload.token = "";
          this.localNotify(
            "Token Error",
            `Unable to connect to token server. Please try again. ${accessTokenError}`,
            "error-theme"
          );
        });
    },
    validateRegistration(payload) {
      this.isUserExisting(payload).then(response => {
        if (response["result"]["exist"]) {
          this.loading = false;
          this.localNotify(
            "Token Error",
            `An account with provided mobile or email already exist!`,
            "error-theme"
          );
        } else {
          this.processRegistration(payload, response["result"]["token"]);
        }
      });
    },
    isUserExisting(payload) {
      return new Promise(resolve => {
        AUTH.verifyUser(payload)
          .then(verificationResponse => {
            resolve(verificationResponse);
          })
          .catch(verificationError => {
            resolve({
              error: verificationError,
              success: false,
              result: { exist: true, error: true }
            });
          });
      });
    },
    processRegistration(payload, token) {
      REG.addUser(payload, token)
        .then(response => {
          if (response["success"]) {
            this.localNotify(
              `Welcome ${this.payload.firstname}`,
              `Account created successfully. Please check your email for your account activation code.`,
              "success-theme"
            );
            this.loading = false;
            this.goBack();
          } else {
            this.loading = false;
            this.localNotify(
              `Registration Failed`,
              `Invalid credentials. Please try again.`,
              "error-theme"
            );
          }
        })
        .catch(err => {
          this.loading = false;
          this.localNotify(
            `Network Error`,
            `Network Error. Please try again ${err}`,
            "error-theme"
          );
        });
    },

    goBack() {
      const self = this;
      const app = self.$f7;
      const router = self.$f7router;
      router.back();
    },
    openLegalPopup(doc) {
      switch (doc) {
        case "privacy":
          this.legal.title = "Privacy Policy";
          this.legal.content = LEGAL.policy;
          break;
        case "terms":
          this.legal.title = "Terms of Use";
          this.legal.content = LEGAL.terms;
          break;
      }
      this.popupOpened = true;
    },

    localNotify(subtitle, text, colorTheme) {
      const self = this;
      const app = self.$f7;
      app.data.notification.show(app, subtitle, text, colorTheme);
    }
  },
  created() {
    Vue.cordova.on("deviceready", () => {
      this.payload.device = Vue.cordova.device;
    });
  }
};
</script>
