export const NOTIFICATION = {
    show: (app, subtitle, text, colorTheme='primary-theme', closeTimeout=4000) => {
        let icon = '';
        switch (colorTheme) {
            case 'success-theme':
                icon = `<i class="icon ${colorTheme}">&#10003;</i>`;
                break;
            case 'primary-theme':
                icon = `<i class="icon ${colorTheme}">&#10003;</i>`;
                break;
            case 'error-theme':
                icon = `<i class="icon ${colorTheme}">&#215;</i>`;
                break;
            default:
                icon = `<i class="icon ${colorTheme}">&#10003;</i>`;
                break;
        }
        const notificationFull = app.notification.create({
            icon,
            title: "Notification",
            titleRightText: "now",
            subtitle,
            text,
            closeTimeout
        });
        notificationFull.open();
    }
}