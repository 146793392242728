/*=========================================================================================
  File Name: globalComponents.js
  Description: Here you can register components globally
  ----------------------------------------------------------------------------------------
  Item Name: Relaxe
  Version: 1.0.0
  Author: Asheori Reactive Technology
  Author URL: https://asheori.com
==========================================================================================*/


import Vue from 'vue';
import HomeView  from '@/components/home.vue';
import AppointmentsView  from '@/components/appointments.vue';

Vue.component(HomeView.name, HomeView);
Vue.component(AppointmentsView.name, AppointmentsView);