<template>
  <f7-page name="home" @page:beforeremove="onPageBeforeRemove" ptr @ptr:refresh="refresh">
    <f7-progressbar v-if="loading" infinite color="multi"></f7-progressbar>
    <!-- Top Navbar -->
    <f7-navbar>
      <f7-nav-left></f7-nav-left>
      <f7-nav-title>Relaxé Providers</f7-nav-title>
      <f7-nav-right>
        <f7-link
          icon-ios="f7:filter"
          icon-aurora="f7:filter"
          icon-md="f7:filter"
          panel-open="right"
        ></f7-link>
      </f7-nav-right>
    </f7-navbar>
    <!-- No Providers-->
    <div v-if="Providers.length === 0" class="p-20 text-center mt-40">
      <img :src="staticImage" alt=":-(" class="asset-img" />
      <div class="p-20 text-center text-white">No provider available.</div>
    </div>

    <!-- Cards -->
    <f7-card v-for="(provider, index) in Providers" :key="index" expandable>
      <transition name="dropdown">
        <f7-card-content :padding="false">
          <div
            :style="{background: `url(${provider.photo.url ? provider.photo.url : defaultAvatar}) no-repeat center top`, 'background-size': 'cover', height: '400px'}"
          >
            <f7-card-header text-color="white">{{ provider.personal.firstname }}</f7-card-header>
            <f7-link
              card-close
              color="white"
              class="card-opened-fade-in"
              :style="{position: 'absolute', right: '15px', top: '15px'}"
              icon-f7="close_round_fill"
            ></f7-link>
          </div>
          <div class="card-content-padding">
            <f7-block class="mb-20 mt-10" v-if="provider.provider.city">
              <f7-icon size="20px" color="blue" f7="placemark"></f7-icon>
              {{ provider.provider.city }}
            </f7-block>
            <f7-block class="mb-10" v-if="provider.interests.length > 0">
              <f7-icon size="20px" color="purple" f7="heart"></f7-icon>
              {{ provider.interests.join(', ') }}
            </f7-block>
            <f7-block-title
              v-if="provider.personal.about"
              class="blockTitle"
            >About {{ provider.personal.firstname }}</f7-block-title>
            <f7-block class="mb-20" v-if="provider.personal.about">
              <p>{{ provider.personal.about }}</p>
            </f7-block>
            <f7-block
              class="mb-10 mt-10"
              v-if="provider.personal.identifier !== candidateProfile.personal.identifier"
            >
              <f7-button
                @click="openBooking(provider)"
                fill
                round
                large
                card-close
              >Book {{ provider.personal.firstname }}</f7-button>
            </f7-block>
            <f7-block-title
              class="blockTitle"
              v-if="provider.preferences.length > 0"
            >Massage Speciality</f7-block-title>
            <f7-block strong class="mb-10" v-if="provider.preferences.length > 0">
              <f7-chip
                class="m-3"
                v-for="(item, index) in provider.preferences"
                :key="index"
                :text="item"
                :media-bg-color="utils.bgColor(item)"
                :media="item.substring(0,1)"
              ></f7-chip>
            </f7-block>
            <f7-block-title class="blockTitle">Engagement</f7-block-title>
            <f7-block strong class="mb-10">
              <f7-list>
                <f7-list-item
                  :style="{fontSize: '14px'}"
                  title="Session Duration"
                  :after="utils.formatTime(provider.provider.duration)"
                >
                  <f7-icon slot="media" size="25px" f7="timer"></f7-icon>
                </f7-list-item>
                <f7-list-item
                  :style="{fontSize: '14px'}"
                  title="Charge per Session"
                  :badge="'₦' + utils.formatPrice(provider.provider.fee)"
                  badge-color="pink"
                >
                  <f7-icon slot="media" size="25px" color="red" f7="card_fill"></f7-icon>
                </f7-list-item>
              </f7-list>
            </f7-block>

            <f7-card
              v-for="(photo, index) in providerPhotos"
              :key="index"
              :style="{borderRadius: '10px', marginBottom: '20px'}"
            >
              <div
                :style="{background: 'url('+ photo && photo.url ? photo.url : defaultAvatar +') no-repeat center top', 'background-size': 'cover', height: '400px', borderRadius: '10px'}"
              ></div>
            </f7-card>
          </div>
        </f7-card-content>
      </transition>
    </f7-card>

    <!-- Booking Popup-->
    <f7-popup class="booking-popup" :opened="popupOpened" @popup:closed="popupOpened = false">
      <f7-page>
        <f7-progressbar v-if="loading" infinite color="multi"></f7-progressbar>
        <f7-navbar>
          <f7-nav-left>
            <img
              :src="payload.provider.photo && payload.provider.photo.url ? payload.provider.photo.url : defaultAvatar"
              class="home-avatar"
              slot="media"
            />
          </f7-nav-left>
          <f7-nav-title
            sliding
          >Booking {{ payload.provider ? payload.provider.firstname : "Anonymous" }}</f7-nav-title>
          <f7-nav-right>
            <f7-link popup-close>Cancel</f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-block-title class="blockTitle ml-10 text-white">Service Type</f7-block-title>
        <f7-list no-hairlines-md class="mt-0">
          <f7-list-input
            label="Massage Service"
            type="select"
            placeholder="Please choose..."
            validate-on-blur
            error-message="Please select massage service!"
            @input="payload.booking.service = $event.target.value"
          >
            <option value selected>Choose Massage</option>
            <option
              v-for="(pref, index) in payload.provider.preferences"
              :key="index"
              :value="pref"
            >{{ pref }}</option>
          </f7-list-input>
        </f7-list>
        <f7-block-title class="blockTitle ml-10 text-white">Appointment Date</f7-block-title>
        <f7-list no-hairlines-md class="mt-0">
          <f7-list-input
            type="date"
            placeholder="Select date"
            validate-on-blur
            error-message="Please select  date!"
            :value="payload.booking.booking_date"
            @input="payload.booking.booking_date = $event.target.value"
          />
        </f7-list>
        <f7-block-title class="blockTitle ml-10 text-white">Appointment Time</f7-block-title>
        <f7-list no-hairlines-md class="mt-0">
          <f7-list-input
            label="Time"
            type="select"
            placeholder="Please choose..."
            validate-on-blur
            error-message="Please select time!"
            @input="payload.booking.booking_time = $event.target.value"
          >
            <option value selected>Choose time</option>
            <option v-for="(time, index) in clock" :key="index" :value="time">{{ time }}</option>
          </f7-list-input>
        </f7-list>
        <f7-block-title class="blockTitle ml-10 text-white">Location</f7-block-title>
        <f7-list>
          <f7-list-input
            type="textarea"
            label="Address"
            validate-on-blur
            error-message="Please enter your address!"
            placeholder="Enter descriptive address"
            :value="payload.booking.booking_location"
            @input="payload.booking.booking_location = $event.target.value"
            resizable
          ></f7-list-input>
        </f7-list>
        <f7-block-title class="blockTitle ml-10 text-white">Personal Note</f7-block-title>
        <f7-list>
          <f7-list-input
            type="textarea"
            label="Note"
            class="text-white"
            placeholder="Anything the provider should or must know..."
            validate-on-blur
            error-message="Please provide instruction with this booking!"
            :value="payload.booking.booking_note"
            @input="payload.booking.booking_note = $event.target.value"
            resizable
          ></f7-list-input>
        </f7-list>
        <transition name="dropdown">
          <f7-block v-if="!loading">
            <div :style="{marginTop: '10px'}">
              <f7-button @click="makeBooking" large round fill raised>Make Booking</f7-button>
            </div>
          </f7-block>
        </transition>
      </f7-page>
    </f7-popup>
  </f7-page>
</template>
<script>
import { mapGetters } from "vuex";
import { utils } from "@/services/helper";
export default {
  name: "home-view",
  data() {
    return {
      utils: utils,
      clock: "6AM 7AM 8AM 9AM 10AM 11AM 12PM 1PM 2PM 3PM 4PM 5PM 6PM 7PM 8PM 9PM".split(
        " "
      ),
      providerPhotos: [],
      payload: {
        fee: 0,
        user_one_signal_id: "",
        provider_one_signal_id: "",
        provider: {},
        user: {},
        booking: {
          service: "",
          booking_date: null,
          booking_location: "",
          booking_time: "6AM",
          booking_note: ""
        },
        confirm_status: false
      },
      walletPaymentPayload: {
        sender: "",
        receiver: "",
        amount: 0,
        note: "",
        participants: {}
      },
      loading: false,
      popupOpened: false,
      staticImage: require("@/assets/icons/spotlight.png"),
      defaultAvatar: require("@/assets/icons/massage.png")
    };
  },
  methods: {
    refresh(event, done) {
      this.loading = true;
      // Update Providers State
      this.$store
        .dispatch("getProviders", {
          data: {}
        })
        .then(() => {
          this.loading = false;
        });

      setTimeout(() => {
        done();
      }, 500);
    },

    localNotify(subtitle, text, colorTheme) {
      const self = this;
      const app = self.$f7;
      app.data.notification.show(app, subtitle, text, colorTheme);
    },

    openBooking(provider) {
      this.loading = true;
      this.checkSubscriptionValidity().then(isValid => {
        if (isValid) {
          this.loading = false;
          this.payload.fee = provider.provider.fee;
          this.payload.user_one_signal_id = this.candidateProfile.one_signal_id;
          this.payload.provider_one_signal_id = provider.one_signal_id;

          this.payload.provider = {
            photo: provider.photo,
            identifier: provider.personal.identifier,
            firstname: provider.personal.firstname,
            lastname: provider.personal.lastname,
            mobile: provider.personal.mobile,
            email: provider.personal.email,
            city: provider.provider.city,
            about: provider.personal.about,
            preferences: provider.preferences,
            interests: provider.interests,
            gallery: []
          };

          this.payload.user = {
            photo: this.candidateProfile.photo,
            identifier: this.candidateProfile.personal.identifier,
            firstname: this.candidateProfile.personal.firstname,
            lastname: this.candidateProfile.personal.lastname,
            mobile: this.candidateProfile.personal.mobile,
            email: this.candidateProfile.personal.email
          };

          this.popupOpened = true;
        } else {
          this.loading = false;
          this.localNotify(
            "Booking Error",
            `Your subscription has expired. Please fund your account and renew your subscription.`,
            "error-theme"
          );
        }
      });
    },

    makeBooking() {
      if (
        this.payload.booking.service &&
        this.payload.booking.booking_time &&
        this.payload.booking.booking_date &&
        this.payload.booking.booking_location &&
        this.payload.booking.booking_note
      ) {
        const app = this.$f7;
        app.dialog.confirm(
          `Go ahead and confirm the booking of massage service from ${this.payload.provider.firstname}?`,
          () => {
            this.loading = true;
            this.processBooking();
          }
        );
      } else {
        this.localNotify(
          "Booking Error",
          `Please provide all information to continue your booking.`,
          "error-theme"
        );
      }
    },

    checkSubscriptionValidity() {
      const today = new Date();
      const endDate = new Date(this.userSubscription.end_date);
      return new Promise(resolve => {
        if (endDate > today) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    },

    processBooking() {
      this.$store
        .dispatch("createUserBooking", {
          data: this.payload
        })
        .then(() => {
          this.localNotify(
            "Booking Successful",
            `Your massage booking with ${this.payload.provider.firstname} has be placed.`,
            "success-theme"
          );

          this.loading = false;
          this.popupOpened = false;
          this.payload = {
            provider: {},
            booking: {
              booking_date: "",
              booking_location: "",
              booking_time: "",
              booking_note: ""
            }
          };
        })
        .catch(err => {
          this.loading = false;
          this.localNotify(
            "Server Error",
            `Unable to place your booking. Please contact support. ${err}`,
            "error-theme"
          );
        });
    },

    onPageBeforeRemove() {
      const self = this;
      self.pickerDescribe.destroy();
    }
  },
  computed: {
    ...mapGetters(["candidateProfile", "Providers", "userSubscription"])
  }
};
</script>