// Import Vue
import Vue from 'vue';

// Import Framework7
import Framework7 from 'framework7/framework7.esm.bundle.js';

// Import Framework7-Vue Plugin
import Framework7Vue from 'framework7-vue/framework7-vue.esm.bundle.js';

// Import Framework7 Styles
import 'framework7/css/framework7.bundle.css';

// Import Icons and App Custom Styles
import '@/css/icons.css';
import '@/css/app.css';

// Import App Component
import App from '@/components/app.vue';

// Globally Registered Components
import './global.components';

// Vuex Store
import store from '@/store/store';

// Init Framework7-Vue Plugin
Framework7.use(Framework7Vue);

// Vue Time Ago
import VueTimeago from 'vue-timeago'
Vue.use(VueTimeago, {
  name: 'Timeago', // Component name, `Timeago` by default
  locale: 'en', // Default locale
});


// Init App
new Vue({
  el: '#app',
  store,
  render: (h) => h(App),
  // Register App Component
  components: {
    app: App
  },
  data: function () {
    return {
      cordova: Vue.cordova
    }
  }
});