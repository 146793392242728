<template>
  <f7-page name="wallet" ptr @ptr:refresh="refresh">
    <f7-progressbar v-if="loading" infinite color="multi"></f7-progressbar>
    <f7-navbar>
      <f7-nav-title>My Appointments</f7-nav-title>
    </f7-navbar>
    <!-- No Bookings -->
    <div v-if="ProviderBookings.length === 0" class="p-20 text-center mt-40">
      <img :src="staticImage" alt=":-(" class="asset-img" />
      <div class="p-20 text-center text-white">No booking available.</div>
    </div>

    <!-- Bookings -->
    <f7-card expandable v-for="(item, index) in ProviderBookings" :key="index">
      <f7-card-content :padding="false">
        <div
          :class="item.confirm_status ? 'bg-color-grey' : 'bg-primary'"
          :style="{height: '300px'}"
        >
          <f7-card-header text-color="white" class="display-block">
            <img
              :src="item.user.photo.url ? item.user.photo.url: defaultAvatar"
              class="booking-avatar"
            />
            <br />
            {{ item.user.firstname }}
            <br />
            <f7-icon f7="calendar_today" size="22px" color="black"></f7-icon>&nbsp;
            <span
              class="text-black"
            >{{ (new Date(item.booking.booking_date)).toDateString() }}</span>
            <br />
            <small class="op-70 small-text">
              <f7-icon f7="time" size="18px" color="black"></f7-icon>&nbsp;
              <span class="text-black">{{ item.booking.booking_time }}</span>
            </small>
            <!-- <br>
            <small class="op-70 small-text">
              <f7-icon f7="timer" size="18px" color="white"></f7-icon>&nbsp;
              <timeago :datetime="item.booking.booking_date"></timeago>
            </small>-->
          </f7-card-header>
          <f7-link
            card-close
            color="white"
            class="card-opened-fade-in"
            :style="{position: 'absolute', right: '15px', top: '15px'}"
            icon-f7="close_round_fill"
          ></f7-link>
        </div>
        <div v-if="subscriptionValid" class="card-content-padding">
          <f7-block v-if="!item.confirm_status" class="mb-20 mt-10">
            <f7-icon size="20px" color="pink" f7="phone"></f7-icon>
            &nbsp;{{ item.user.mobile ? item.user.mobile : 'Undisclosed' }}
          </f7-block>
          <f7-block class="mb-20">
            <f7-icon size="20px" color="blue" f7="placemark"></f7-icon>
            &nbsp;{{ item.booking.booking_location }}
          </f7-block>

          <f7-block class="mt-20 mb-20" v-if="!item.confirm_status">
            <f7-row>
              <f7-col>
                <f7-button @click="callNumber(item.user.mobile)" fill round large card-close>
                  <f7-icon f7="phone" color="white"></f7-icon>&nbsp;Call
                </f7-button>
              </f7-col>
              <f7-col v-if="!item.checkin.status && !loading">
                <f7-button @click="checkin(item._id)" color="blue" fill round large card-close>
                  <f7-icon f7="placemark_fill" color="white"></f7-icon>&nbsp;Checkin
                </f7-button>
              </f7-col>
              <f7-col v-if="item.checkin.status && !item.checkout.status && !loading">
                <f7-button @click="checkout(item._id)" color="pink" fill round large card-close>
                  <f7-icon f7="placemark_fill" color="white"></f7-icon>&nbsp;Checkout
                </f7-button>
              </f7-col>
            </f7-row>
          </f7-block>
          <f7-block-title class="blockTitle">Massage Service Booked</f7-block-title>
          <f7-block strong class="mb-10">
            <f7-chip
              class="m-3"
              :text="item.booking.service ? item.booking.service : 'Unspecified'"
              :media-bg-color="utils.bgColor(item.booking.service ? item.booking.service : 'Unspecified')"
              :media="(item.booking.service ? item.booking.service : 'Unspecified').substring(0,1)"
            ></f7-chip>
          </f7-block>

          <f7-block-title class="blockTitle">Booking Note</f7-block-title>
          <f7-block strong class="mb-10">{{ item.booking.booking_note }}</f7-block>
        </div>

        <div v-if="!subscriptionValid" class="card-content-padding">
          <div class="p-20 text-center mt-40">
            <img :src="staticImage" alt=":-(" class="asset-img" />
            <div class="p-20 text-center text-white">Please subscribe to view booking details.</div>
          </div>
        </div>

        <f7-block class="mt-10 mb-10" v-if="!item.confirm_status">
          <p>
            <f7-button @click="cancelBooking(item)" color="red" fill round large card-close>
              <f7-icon f7="trash" size="24px" color="white"></f7-icon>&nbsp;Cancel Booking
            </f7-button>
          </p>
        </f7-block>
      </f7-card-content>
    </f7-card>
  </f7-page>
</template>
<script>
import { mapGetters } from "vuex";
import Vue from "vue";
import { utils } from "@/services/helper";
export default {
  name: "appointments-view",
  data() {
    return {
      loading: false,
      utils: utils,
      payload: {},
      staticImage: require("@/assets/icons/door.png"),
      defaultAvatar: require("@/assets/icons/add-user.png")
    };
  },
  methods: {
    refresh(event, done) {
      this.loading = true;
      // Update Providers State
      this.$store.dispatch("getProviderBookings", {
        data: this.candidateProfile.personal.identifier
      });

      setTimeout(() => {
        done();
        this.loading = false;
      }, 500);
    },

    callNumber(phone) {
      Vue.cordova.on("deviceready", () => {
        const cordovaCall = cordova.plugins.CordovaCall;
        cordovaCall.callNumber(
          phone,
          onSuccess => {},
          onError => {
            this.localNotify(
              "Call Error",
              `Unable to make this call, please try again. Error: ${onError}`,
              "error-theme"
            );
          }
        );
      });
    },

    cancelBooking(booking) {
      const app = this.$f7;
      app.dialog.confirm(
        "Are you sure you want to cancel this booking?",
        () => {
          this.$store
            .dispatch("cancelBooking", {
              data: booking
            })
            .then(() => {
              // Response
              this.localNotify(
                "Cancelled Successfully!",
                `Your booking with ${booking.user.firstname} was successfully cancelled`,
                "success-theme"
              );
            })
            .catch(err => {
              this.localNotify(
                "Cancellation Error",
                `Unable to cancel this booking, please try again. Error: ${err}`,
                "error-theme"
              );
            });
        }
      );
    },

    checkin(id) {
      const app = this.$f7;
      app.dialog.confirm(
        "Are you sure you want to checkin for this booking?",
        () => {
          this.loading = true;
          Vue.cordova.on("deviceready", () => {
            Vue.cordova.geolocation.getCurrentPosition(
              position => {
                const payload = {
                  id,
                  checkin: {
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                    date: new Date(),
                    status: true
                  }
                };
                this.$store
                  .dispatch("checkinBooking", {
                    data: payload
                  })
                  .then(() => {
                    // Response
                    this.loading = false;
                    this.localNotify(
                      "Checkin Successfully!",
                      `Your checkin was successful!`,
                      "success-theme"
                    );
                  })
                  .catch(err => {
                    this.loading = false;
                    this.localNotify(
                      "Checkin Error",
                      `Unable to check you in for this booking, please try again. Error: ${err}`,
                      "error-theme"
                    );
                  });
              },
              err => {
                this.loading = false;
                this.localNotify(
                  "Checkout Error",
                  `Unable to get your current location, please try again. Error: ${err.message}`,
                  "error-theme"
                );
              },
              {
                maximumAge: 3000,
                timeout: 30000,
                enableHighAccuracy: true
              }
            );
          });
        }
      );
    },

    checkout(id) {
      const app = this.$f7;
      app.dialog.confirm(
        "Are you sure you want to checkout for this booking?",
        () => {
          this.loading = true;
          Vue.cordova.on("deviceready", () => {
            Vue.cordova.geolocation.getCurrentPosition(
              position => {
                const payload = {
                  id,
                  checkout: {
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                    date: new Date(),
                    status: true
                  }
                };
                this.$store
                  .dispatch("checkoutBooking", {
                    data: payload
                  })
                  .then(() => {
                    // Response
                    this.loading = false;
                    this.localNotify(
                      "Checkout Successfully!",
                      `Your checkout was successful!`,
                      "success-theme"
                    );
                  })
                  .catch(err => {
                    this.loading = false;
                    this.localNotify(
                      "Checkout Error",
                      `Unable to check you out for this booking, please try again. Error: ${err}`,
                      "error-theme"
                    );
                  });
              },
              err => {
                this.loading = false;
                this.localNotify(
                  "Checkout Error",
                  `Unable to get your current location, please try again. Error: ${err.message}`,
                  "error-theme"
                );
              },
              {
                maximumAge: 3000,
                timeout: 30000,
                enableHighAccuracy: true
              }
            );
          });
        }
      );
    },

    subscriptionValid() {
      const today = new Date();
      const endDate = new Date(this.userSubscription.end_date);
      if (endDate > today) {
        return true;
      } else {
        return false;
      }
    },

    localNotify(subtitle, text, colorTheme) {
      const self = this;
      const app = self.$f7;
      app.data.notification.show(app, subtitle, text, colorTheme);
    }
  },
  computed: {
    ...mapGetters(["candidateProfile", "ProviderBookings", "userSubscription"])
  }
};
</script>