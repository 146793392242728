import LoginPage from '@/pages/login.vue';
import LandingPage from '@/pages/landing.vue';
import MessagingPage from '@/pages/messaging.vue';
import ChatPage from '@/pages/chat.vue';
import BookingsPage from '@/pages/bookings.vue';
import WalletPage from '@/pages/wallet.vue';
import SettingsPage from '@/pages/settings.vue';
import GalleryPage from '@/pages/gallery.vue';
import ForgotPage from '@/pages/forgot.vue';
import RegisterPage from '@/pages/register.vue';
import NotFoundPage from '@/pages/404.vue';

import state from '@/store/state';

var routes = [
  {
    path: '/',
    async(to, from, resolve, reject) {
      const isAuthenticated =  state.isAuthenticated;
      const lastPage = window.sessionStorage.getItem('last_page');
      if (!isAuthenticated) {
        resolve({ component: LoginPage });
      } else {
        if(lastPage === 'settings') {
          resolve({ component: SettingsPage });
        } else {
          resolve({ component: LandingPage });
        }
        
      }
    }
  },
  {
    path: '/home/',
    component: LandingPage,
  },
  {
    path: '/login/',
    component: LoginPage,
  },
  {
    path: '/bookings/',
    component: BookingsPage,
  },
  {
    path: '/wallet/',
    component: WalletPage,
  },
  {
    path: '/messaging/',
    component: MessagingPage,
  },
  {
    path: '/chat/',
    component: ChatPage,
  },
  {
    path: '/settings/',
    component: SettingsPage,
  },
  {
    path: '/gallery/',
    component: GalleryPage,
  },
  {
    path: '/register/',
    component: RegisterPage,
  },
  {
    path: '/forgot/',
    component: ForgotPage,
  },
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;
