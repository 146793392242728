<template>
  <f7-page name="settings">
    <f7-progressbar v-if="loading" infinite color="multi"></f7-progressbar>
    <f7-navbar>
      <f7-nav-left>
        <f7-link
          @click="openGeneralSettings"
          icon-ios="f7:settings"
          icon-aurora="f7:settings"
          icon-md="f7:settings"
          prevent-router
          :animate="true"
        ></f7-link>
      </f7-nav-left>
      <f7-nav-title>Profile Settings</f7-nav-title>
      <f7-nav-right>
        <f7-link
          @click="logout"
          color="red"
          icon-ios="f7:exit_round"
          icon-aurora="f7:exit_round"
          icon-md="f7:exit_round"
          prevent-router
          :animate="true"
        ></f7-link>
      </f7-nav-right>
    </f7-navbar>
    <!-- Avatar -->
    <transition name="dropdown">
      <f7-block v-if="!hasLogout && !editMode" class="text-center mt-10">
        <img
          :src="candidateProfile.photo.url ? candidateProfile.photo.url : defaultAvatar"
          class="m-auto profile-avatar"
          @click="$refs.galleryActionGroup.open()"
        />
      </f7-block>
    </transition>
    <!-- /Avatar -->

    <!-- View Mode -->
    <transition name="dropdown">
      <f7-card v-if="!hasLogout && !editMode" class="curved pb-10">
        <f7-list no-hairlines-md form>
          <f7-list-input
            outline
            floating-label
            label="Firstname"
            type="text"
            disabled
            :value="candidateProfile.personal.firstname"
          ></f7-list-input>

          <f7-list-input
            outline
            floating-label
            label="Lastname"
            type="text"
            disabled
            :value="candidateProfile.personal.lastname"
          ></f7-list-input>

          <f7-list-input
            outline
            floating-label
            label="E-mail"
            type="email"
            placeholder="E-mail"
            disabled
            :value="candidateProfile.personal.email"
          ></f7-list-input>

          <f7-list-input
            outline
            floating-label
            label="Mobile Phone"
            type="tel"
            disabled
            :value="candidateProfile.personal.mobile"
          ></f7-list-input>
          <div class="p-20">
            I identify as
            <span class="text-primary">{{ candidateProfile.personal.gender }}</span>
          </div>
        </f7-list>
        <!-- Edit Buttom -->
        <f7-block v-if="!loading && !hasLogout && !editMode" class="mt-10 mb-10">
          <f7-button @click="openEditMode" round large raised outline card-close>
            <f7-icon size="20px" f7="edit"></f7-icon>&nbsp;Edit Profile
          </f7-button>
        </f7-block>
        <f7-block v-if="!loading && !hasLogout && !editMode" class="mt-10 mb-10">
          <f7-button @click="openSecuritySheet" color="white" fill round large raised card-close>
            <f7-icon color="red" size="20px" f7="padlock"></f7-icon>&nbsp;
            <span class="text-danger">Update Password</span>
          </f7-button>
        </f7-block>
      </f7-card>
    </transition>
    <!-- /View Mode -->

    <!-- Edit Mode -->
    <transition name="dropdown">
      <f7-card v-if="!hasLogout && editMode" class="curved pb-10">
        <f7-list no-hairlines-md form>
          <f7-list-input
            outline
            floating-label
            label="Firstname"
            type="text"
            :value="payload.personal.firstname"
            @input="payload.personal.firstname = $event.target.value"
            error-message="Firstname is required!"
            required
            validate-on-blur
          ></f7-list-input>

          <f7-list-input
            outline
            floating-label
            label="Lastname"
            type="text"
            error-message="Lastname is required!"
            required
            validate-on-blur
            :value="payload.personal.lastname"
            @input="payload.personal.lastname = $event.target.value"
          ></f7-list-input>

          <f7-list-input
            outline
            floating-label
            label="E-mail"
            type="email"
            placeholder="E-mail"
            required
            validate-on-blur
            disabled
            :value="payload.personal.email"
            @input="payload.personal.email = $event.target.value"
          ></f7-list-input>

          <f7-list-input
            outline
            floating-label
            label="Mobile Phone"
            type="tel"
            error-message="Please provide a valid Nigerian mobile phone number!"
            required
            validate-on-blur
            pattern="^[0-9]{11}"
            :value="payload.personal.mobile"
            @input="payload.personal.mobile = $event.target.value"
          ></f7-list-input>

          <f7-list>
            <div class="p-20">
              <small class="text-primary">I IDENTIFY AS...</small>
            </div>
            <f7-list-item
              value="Female"
              :checked="candidateProfile.personal.gender === 'Female'"
              @change="payload.personal.gender = $event.target.value"
              radio
              name="gender"
              title="Female"
            ></f7-list-item>
            <f7-list-item
              value="Male"
              :checked="candidateProfile.personal.gender === 'Male'"
              @change="payload.personal.gender = $event.target.value"
              radio
              name="gender"
              title="Male"
            ></f7-list-item>
          </f7-list>
        </f7-list>
        <!-- Save Buttom -->
        <f7-block v-if="!loading && !hasLogout && editMode" class="mt-20 mb-10">
          <f7-button @click="save" fill round large card-close>
            <f7-icon size="20px" f7="check" color="white"></f7-icon>&nbsp;Save
          </f7-button>
        </f7-block>
        <!-- Cancel Buttom -->
        <f7-block v-if="!loading && !hasLogout && editMode" class="mt-10 mb-10">
          <f7-button @click="editMode=false" round large card-close>
            <f7-icon size="20px" f7="arrow_left" color="white"></f7-icon>&nbsp;Cancel
          </f7-button>
        </f7-block>
      </f7-card>
    </transition>
    <!-- /Edit Mode -->

    <!-- General Settings Popup-->
    <f7-popup
      class="general-settings-popup"
      :opened="popupOpened"
      @popup:closed="popupOpened = false"
    >
      <f7-page>
        <f7-navbar>
          <f7-nav-left>
            <f7-link
              icon-ios="f7:arrow_left"
              icon-aurora="f7:arrow_left"
              icon-md="f7:arrow_left"
              popup-close
            >Cancel</f7-link>
          </f7-nav-left>
          <f7-nav-title sliding>General Settings</f7-nav-title>
          <f7-nav-right>
            <f7-link
              icon-ios="f7:arrow_check"
              icon-aurora="f7:arrow_check"
              icon-md="f7:arrow_check"
              @click="saveGeneralSettings"
              prevent-router
              :animate="true"
            >Save</f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-progressbar v-if="loading" infinite color="multi"></f7-progressbar>
        <div v-if="popupOpened">
          <f7-block-title>Notification</f7-block-title>
          <f7-list>
            <f7-list-item title="Mobile">
              <f7-toggle
                :checked="payload.notification.mobile"
                @change="toogleMe(toogleControl.PUSH)"
                slot="after"
              ></f7-toggle>
            </f7-list-item>
            <f7-list-item title="Email">
              <f7-toggle
                :checked="payload.notification.email"
                slot="after"
                @change="toogleMe(toogleControl.EMAIL)"
              ></f7-toggle>
            </f7-list-item>
          </f7-list>
          <f7-block-title>Account</f7-block-title>
          <f7-list>
            <f7-list-item title="Visibility">
              <f7-toggle
                :checked="payload.online"
                slot="after"
                @change="toogleMe(toogleControl.ONLINE)"
              ></f7-toggle>
            </f7-list-item>
            <f7-list-item title="Pro Account">
              <f7-toggle
                :checked="payload.provider.status"
                slot="after"
                @change="toogleMe(toogleControl.PRO)"
              ></f7-toggle>
            </f7-list-item>
          </f7-list>
          <transition name="dropdown">
            <div v-if="payload.provider.status">
              <!-- Pro Account Settings -->

              <f7-block-title class="text-center text-primary">About You (140 characters max)</f7-block-title>
              <f7-card class="curved">
                <f7-list>
                  <f7-list-input
                    maxlength="140"
                    class="text-white"
                    :value="payload.personal.about"
                    @input="payload.personal.about = $event.target.value.substring(0,140)"
                    outline
                    type="textarea"
                    resizable
                  ></f7-list-input>
                </f7-list>
              </f7-card>

              <f7-block-title class="text-center text-primary">My Services</f7-block-title>
              <f7-card class="curved">
                <f7-list>
                  <f7-list-item
                    v-for="(preference, index) in preferences"
                    :key="index"
                    @change="checkPreferences"
                    checkbox
                    :checked="payload.preferences.indexOf(preference) >= 0"
                    name="preferences"
                    :title="preference"
                    :value="preference"
                  ></f7-list-item>
                </f7-list>
              </f7-card>

              <f7-block-title class="text-center text-primary">My Service Duration</f7-block-title>
              <f7-card class="curved">
                <f7-list no-hairlines-md>
                  <f7-list-item
                    v-for="(duration, index) in durations"
                    :key="index"
                    @change="payload.provider.duration = $event.target.value"
                    radio
                    name="duration"
                    :checked="payload.provider.duration == duration"
                    :value="duration"
                    :title="duration + 'mins'"
                  ></f7-list-item>
                </f7-list>
              </f7-card>
              <f7-block-title class="text-center text-primary">My Service Charge</f7-block-title>
              <f7-card class="curved pb-10">
                <f7-list class="mt-0">
                  <f7-list-input
                    minlength="5"
                    maxlength="6"
                    floating-label
                    outline
                    clear-button
                    label="Amount (Naira)"
                    type="number"
                    error-message="Enter numbers only please!"
                    required
                    validate
                    pattern="[0-9]"
                    :value="payload.provider.fee"
                    @input="payload.provider.fee = $event.target.value"
                  ></f7-list-input>
                </f7-list>
              </f7-card>
              <f7-block-title class="text-center text-primary">My Location</f7-block-title>
              <f7-card class="curved">
                <f7-list no-hairlines-md>
                  <f7-list-item
                    v-for="(location, index) in locations"
                    :key="index"
                    @change="payload.provider.city = $event.target.value"
                    radio
                    name="location"
                    :checked="payload.provider.city == location"
                    :value="location"
                    :title="location"
                  ></f7-list-item>
                </f7-list>
              </f7-card>
              <!-- /Pro Account Settings -->
            </div>
          </transition>
          <transition name="dropdown">
            <f7-block v-if="!loading">
              <div :style="{marginTop: '10px'}">
                <f7-button @click="saveGeneralSettings" large round fill raised>
                  <f7-icon size="16px" f7="check" color="white"></f7-icon>&nbsp;Save
                </f7-button>
              </div>
            </f7-block>
          </transition>
        </div>
      </f7-page>
    </f7-popup>
    <!-- / General Settings Popup-->

    <!-- Password Change Sheet -->
    <f7-sheet class="security-sheet" :opened="sheetOpened" @sheet:closed="sheetOpened = false">
      <f7-toolbar class="primary-theme-bg top-curve">
        <div class="left p-10 text-white">Update Account Password</div>
        <div class="right">
          <f7-link
            class="text-white"
            icon-ios="f7:arrow_down"
            icon-aurora="f7:arrow_down"
            icon-md="f7:arrow_down"
            sheet-close
          >Close</f7-link>
        </div>
      </f7-toolbar>
      <!-- Scrollable sheet content -->
      <f7-page-content>
        <transition name="dropdown">
          <f7-list class="mt-0 bg-none">
            <f7-list-input
              class="bg-none"
              floating-label
              outline
              clear-button
              label="New Password"
              type="password"
              minlength="6"
              error-message="Enter new password (min. 6 characters)"
              required
              validate
              :value="securityPayload.password"
              @input="securityPayload.password = $event.target.value"
            ></f7-list-input>
            <f7-list-input
              class="bg-none"
              floating-label
              outline
              clear-button
              label="Confirm Password"
              type="password"
              error-message="Password mismatch!"
              required
              validate
              :value="securityPayload.confirmPassword"
              @input="securityPayload.confirmPassword = $event.target.value"
            ></f7-list-input>
            <f7-block>
              <f7-button large round fill raised @click="saveSecuritySettings">
                <f7-icon ios="f7:check" aurora="f7:check" md="f7:check"></f7-icon>&nbsp;Update Password
              </f7-button>
            </f7-block>
          </f7-list>
        </transition>
      </f7-page-content>
    </f7-sheet>
    <!-- /Password Change Sheet -->

    <!-- Gallery Action Group -->
    <f7-actions ref="galleryActionGroup">
      <f7-actions-group>
        <f7-actions-label>Select image source</f7-actions-label>
        <f7-actions-button @click="openCamera(1)">
          <f7-icon f7="camera" slot="media"></f7-icon>Phone Camera
        </f7-actions-button>
        <f7-actions-button @click="openCamera(0)">
          <f7-icon f7="images" slot="media"></f7-icon>Phone Gallery
        </f7-actions-button>
        <f7-actions-button color="red">
          <f7-icon f7="arrow_left" slot="media"></f7-icon>Cancel
        </f7-actions-button>
      </f7-actions-group>
    </f7-actions>
    <!-- /Gallery Action Group -->
  </f7-page>
</template>
<script>
import { mapGetters } from "vuex";
import { utils } from "@/services/helper";
import { AppFormSettings } from "@/store/app.form.settings";
import { AUTH } from "@/services/auth.service";
import Vue from "vue";

export default {
  data() {
    return {
      sheetOpened: false,
      banks: AppFormSettings.banks,
      utils: utils,
      preferences: [
        "Chair",
        "Couples",
        "Deep Tissue",
        "Nuru",
        "Prenatal",
        "Swedish"
      ],
      locations: ["Lagos", "Abuja", "Port-Harcourt", "Calabar"],
      durations: [60, 90, 120, 180],
      toogleControl: {
        PUSH: "PUSH",
        EMAIL: "EMAIL",
        ONLINE: "ONLINE",
        PRO: "PRO"
      },
      popupOpened: false,
      editMode: false,
      hasLogout: false,
      loading: false,
      securityPayload: {
        email: "",
        password: "",
        confirmPassword: "",
        token: ""
      },
      payload: {
        personal: {
          firstname: "",
          lastname: "",
          email: "",
          mobile: "",
          gender: ""
        }
      },
      defaultAvatar: require("@/assets/icons/add-user.png")
    };
  },
  methods: {
    loadAllImages() {
      let allimages = document.getElementsByTagName("img");
      for (let i = 0; i < allimages.length; i++) {
        if (allimages[i].getAttribute("data-src")) {
          allimages[i].setAttribute(
            "src",
            allimages[i].getAttribute("data-src")
          );
        }
      }
    },

    openSecuritySheet() {
      const self = this;
      self.$f7.sheet.open(".security-sheet");
    },
    closeSecuritySheet() {
      // Close sheet
      const self = this;
      self.$f7.sheet.close(".security-sheet");
    },

    openCamera(source) {
      this.localNotify(
        "Notification",
        `Please download Relaxe mobile app to update your profile photo.`,
        "default-theme"
      );
    },

    toTitleCase(str) {
      return str.replace(/\w\S*/g, txt => {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },

    openEditMode() {
      this.payload = { ...this.payload, ...this.candidateProfile };
      this.editMode = true;
    },

    openGeneralSettings() {
      this.payload = { ...this.payload, ...this.candidateProfile };
      this.popupOpened = true;
    },

    toogleMe(control) {
      switch (control) {
        case "PUSH":
          this.payload.notification.mobile = this.payload.notification.mobile
            ? false
            : true;
          break;
        case "EMAIL":
          this.payload.notification.email = this.payload.notification.email
            ? false
            : true;
          break;
        case "ONLINE":
          this.payload.online = this.payload.online ? false : true;
          break;
        case "PRO":
          this.payload.provider.status = this.payload.provider.status
            ? false
            : true;
          break;
        default:
          break;
      }
    },

    checkPreferences(event) {
      const self = this;
      const value = event.target.value;
      if (event.target.checked) {
        self.payload.preferences.push(value);
      } else {
        self.payload.preferences.splice(
          self.payload.preferences.indexOf(value),
          1
        );
      }
    },

    saveSecuritySettings() {
      this.loading = true;
      this.securityPayload.email = this.candidateProfile.personal.email;
      if (
        this.securityPayload.password &&
        this.securityPayload.password.length >= 6 &&
        this.securityPayload.password === this.securityPayload.confirmPassword
      ) {
        // if form have no errors
        AUTH.getAuthAcessToken()
          .then(accessTokenResponse => {
            this.securityPayload.token = accessTokenResponse["result"]["token"];
            this.$store
              .dispatch("updateUserSecurity", {
                data: this.securityPayload
              })
              .then(() => {
                this.loading = false;
                this.closeSecuritySheet();
                this.localNotify(
                  "Password Updated",
                  `Password updated successfully!`,
                  "success-theme"
                );
              })
              .catch(err => {
                this.loading = false;
                this.localNotify(
                  "Server Error",
                  `Unable to update your password. Please try again. ${err}`,
                  "error-theme"
                );
              });
          })
          .catch(accessTokenError => {
            this.$vs.loading.close();
            this.localNotify(
              "Server Error",
              `Unable to update your password. Please try again. ${accessTokenError}`,
              "error-theme"
            );
          });
      } else {
        this.loading = false;
        this.localNotify(
          "Password Error",
          `Please provide password change requirement. New password and confirm password must match!`,
          "error-theme"
        );
      }
    },

    saveGeneralSettings() {
      this.loading = true;
      // if form have no errors
      this.$store
        .dispatch("updateUserGeneralSettings", {
          data: this.payload
        })
        .then(() => {
          this.loading = false;
          this.popupOpened = false;
          this.localNotify(
            "Account Updated",
            `Account settings updated successfully!`,
            "success-theme"
          );
        })
        .catch(err => {
          this.loading = false;
          this.localNotify(
            "Server Error",
            `Unable to update your account settings. Please try again. ${err}`,
            "error-theme"
          );
        });
    },

    save() {
      if (
        this.payload.personal.firstname &&
        this.payload.personal.lastname &&
        this.payload.personal.mobile
      ) {
        this.loading = true;
        // if form have no errors
        this.$store
          .dispatch("updateUserProfile", {
            data: this.payload
          })
          .then(() => {
            this.loading = false;
            this.editMode = false;
            this.localNotify(
              "Profile Updated",
              `Profile updated successfully!`,
              "success-theme"
            );
          })
          .catch(err => {
            this.loading = false;
            this.localNotify(
              "Server Error",
              `Unable to update your profile. Please try again. ${err}`,
              "error-theme"
            );
          });
      } else {
        this.localNotify(
          "Required Data Error",
          `Please provide all information and try again.`,
          "error-theme"
        );
      }
    },

    localNotify(subtitle, text, colorTheme) {
      const self = this;
      const app = self.$f7;
      app.data.notification.show(app, subtitle, text, colorTheme);
    },

    logout() {
      this.hasLogout = true;
      const self = this;
      const router = self.$f7router;
      this.$store.commit("LOGOUT", { data: false });
      window.sessionStorage.setItem("last_page", "settings");
      router.navigate("/login/");
    }
  },
  computed: {
    ...mapGetters(["candidateProfile"])
  }
};
</script>
