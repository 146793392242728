/*=========================================================================================
  File Name: mutations.js
  Description: Vuex Store - mutations
  ----------------------------------------------------------------------------------------
  Item Name: A.R.T Web Frame
  Version: 1.0.0
  Author: Asheori Reactive Technology
  Author URL: https://asheori.com
==========================================================================================*/
import { BaseUserState, BaseWalletState } from './base.state'

const mutations = {


	// ////////////////////////////////////////////
	// USER STATE
	// ////////////////////////////////////////////

	UPDATE_USER_SUBSCRIPTION(state, payload) {
		state.UserSubscription = payload.data;
	},

	UPDATE_SUBSCRIPTION_PRICING(state, payload) {
		state.SubscriptionPricing = payload.data;
	},


	UPDATE_USER_WALLET(state, payload) {
		payload.data > 0 ? state.Wallet = { ...state.Wallet, active: true } : state.Wallet = { ...state.Wallet, active: false };
		state.Wallet = { ...state.Wallet, balance: payload.data };
	},

	UPDATE_USER_WALLET_TRANSACTIONS(state, payload) {
		state.Wallet.transactions = payload.data;
	},

	PROVIDERS(state, payload) {
		state.Providers = payload.data
	},

	USER_BOOKINGS(state, payload) {
		state.UserBookings = payload.data
	},

	PROVIDER_BOOKINGS(state, payload) {
		state.ProviderBookings = payload.data
	},

	ADD_USER_BOOKING(state, payload) {
		state.UserBookings = [...state.UserBookings, payload.data];
	},

	UPDATE_USER_BOOKING(state, payload) {
		state.UserBookings = state.UserBookings.map(booking => {
			if (booking._id === payload.data._id) {
				return payload.data;
			}
			return booking;
		});
	},

	CONFIRM_USER_BOOKING(state, payload) {
		state.UserBookings = state.UserBookings.map(booking => {
			if (booking._id === payload.data._id) {
				booking.confirm_status = true;
				return booking;
			}
			return booking;
		});
	},

	CHECKIN_BOOKING(state, payload) {
		state.ProviderBookings = state.ProviderBookings.map(booking => {
			if (booking._id === payload.data.id) {
				booking.checkin = payload.data.checkin;
				return booking;
			}
			return booking;
		});
	},

	CHECKOUT_BOOKING(state, payload) {
		state.ProviderBookings = state.ProviderBookings.map(booking => {
			if (booking._id === payload.data.id) {
				booking.checkout = payload.data.checkout;
				return booking;
			}
			return booking;
		});
	},

	BURN_BOOKING(state, payload) {
		state.UserBookings = state.UserBookings.filter(
			item => item._id != payload.data._id
		);
	},

	CANCEL_BOOKING(state, payload) {
		state.ProviderBookings = state.ProviderBookings.filter(
			item => item._id != payload.data._id
		);
	},

	GALLERY(state, payload) {
		state.Gallery = payload.data;
	},


	ADD_USER_GALLERY(state, payload) {
		state.Gallery = [payload.data,...state.Gallery];
	},

	REMOVE_IMAGE_FROM_GALLERY(state, payload) {
		state.Gallery = state.Gallery.filter(
			item => item._id != payload.data._id
		);
	},

	UPDATE_NOTIFICATION_SETTINGS(state, payload) {
		state.AppActiveUser.notification = payload.data.notification;
	},

	UPDATE_ONLINE_STATUS(state, payload) {
		state.AppActiveUser.online = payload.data.online;
	},

	UPDATE_PROVIDER_STATUS(state, payload) {
		state.AppActiveUser.provider = payload.data.provider;
	},

	UPDATE_USER_VERIFY_EMAIL(state, payload) {
		state.AppActiveUser.verify.email = payload.data.status;
	},

	UPDATE_USER_VERIFY_MOBILE(state, payload) {
		state.AppActiveUser.verify.mobile = payload.data.status;
	},

	UPDATE_USER_AUTHSTATE(state, payload) {
		state.isAuthenticated = payload.data;
	},

	UPDATE_USER_AUTHORIZATION(state, payload) {
		state.authorization = payload.data;
	},


	UPDATE_USER_PROFILE(state, payload) {
		state.AppActiveUser = {
			...state.AppActiveUser,
			...payload.data
		};
	},

	UPDATE_USER_BOOKING(state, payload) {
		state.UserBookings.push(payload.data);
	},

	UPDATE_USER_FINANCIAL(state, payload) {
		state.AppActiveUser = {
			...state.AppActiveUser,
			financial: payload.data.financial
		};
	},


	UPDATE_USER_PHOTO(state, payload) {
		state.AppActiveUser = {
			...state.AppActiveUser,
			photo: payload.data
		};
	},
	

	UPDATE_USER_SECURITY(state, payload) {
		console.log(payload);
	},


	// SIGN OUT

	LOGOUT(state, payload) {
		state.isAuthenticated = payload.data;
		state.AppActiveUser = BaseUserState;
		state.Wallet = BaseWalletState;
		state.Wallet = {
			...state.Wallet,
			...BaseWalletState
		};
		state.authorization = {};
		state.UserBookings = [];
		state.ProviderBookings = [];
		state.Gallery = [];
		window.localStorage.clear();
		window.sessionStorage.clear();
	},
}

export default mutations