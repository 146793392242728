/*=========================================================================================
  File Name: profile.services.js
  Description: Profile API Services
  ----------------------------------------------------------------------------------------
  Item Name: A.R.T Web Frame
  Version: 1.0.0
  Author: Asheori Reactive Technology
  Author URL: https://asheori.com
==========================================================================================*/
import * as axios from "axios";
import {
    store
} from "./service.store";
import 
    state
 from "../store/state";
import {
    HTTPAUTH
} from "./http.common";

export const PROFILE = {
    // PHOTO UPLOAD
    uploadCoverPhoto: (file_name, file) => {
        return new Promise((resolve, reject) => {
            const formData = new FormData(),
                xhr = new XMLHttpRequest();
            formData.append('coverphoto', file, file_name);
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        resolve(JSON.parse(xhr.response));
                    } else {
                        reject(xhr.response);
                    }
                }
            };
            xhr.open('POST', store.api.config.image_endpoint + '/upload/file', true);
            xhr.send(formData);
        });
    },

    updateResource(payload, endpointExtention) {
        const appState = JSON.parse(sessionStorage.getItem("relaxe_user"));
        const HTTPDATA = axios.create({
            baseURL: `${store.api.config.data_endpoint}/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': store.api.config.DEFAULT_AUTHENTICATION_TOKEN,
                'token': appState.authorization ? appState.authorization["token"] : '',
                'identity': appState.authorization ? appState.authorization["identity"] : ''
            }
        });
        return new Promise((resolve, reject) => {
            HTTPDATA.patch(endpointExtention, payload)
                .then(addResponse => {
                    resolve(addResponse.data);
                }).catch(errResponse => {
                    reject(errResponse);
                });
        });
    },

    createResource(payload, endpointExtention) {
        const appState = JSON.parse(sessionStorage.getItem("relaxe_user"));
        const HTTPDATA = axios.create({
            baseURL: `${store.api.config.data_endpoint}/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': store.api.config.DEFAULT_AUTHENTICATION_TOKEN,
                'token': appState.authorization ? appState.authorization["token"] : '',
                'identity': appState.authorization ? appState.authorization["identity"] : ''
            }
        });
        return new Promise((resolve, reject) => {
            HTTPDATA.post(endpointExtention, payload)
                .then(addResponse => {
                    resolve(addResponse.data);
                }).catch(errResponse => {
                    reject(errResponse);
                });
        });
    },

    updateSecurityResource(payload, endpointExtention) {
        return new Promise((resolve, reject) => {
            HTTPAUTH.patch(endpointExtention, payload)
                .then(addResponse => {
                    resolve(addResponse.data);
                }).catch(errResponse => {
                    reject(errResponse);
                });
        });
    },

    deleteResource(payload, endpointExtention) {
        const appState = JSON.parse(sessionStorage.getItem("relaxe_user"));
        const HTTPDATA = axios.create({
            baseURL: `${store.api.config.data_endpoint}/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': store.api.config.DEFAULT_AUTHENTICATION_TOKEN,
                'token': appState.authorization ? appState.authorization["token"] : '',
                'identity': appState.authorization ? appState.authorization["identity"] : ''
            }
        });
        return new Promise((resolve, reject) => {
            HTTPDATA.patch(endpointExtention, payload)
                .then(
                    addResponse => {
                        resolve(addResponse.data);
                    }).catch(
                    errResponse => {
                        reject(errResponse);
                    }
                );
        });
    }
}