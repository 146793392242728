<template>
  <f7-page no-toolbar login-screen>
    <f7-navbar back-link="Back"></f7-navbar>
    <transition name="dropdown">
      <div class="text-center mt-40">
        <img class="logo" :src="staticLogo" />
      </div>
    </transition>
    <transition name="dropdown">
      <f7-list form>
        <f7-list-input
          floating-label
          outline
          required
          validate
          label="Account Email"
          type="email"
          :value="email"
          @input="email = $event.target.value"
        ></f7-list-input>
      </f7-list>
    </transition>
    <f7-progressbar v-if="loading" infinite color="multi"></f7-progressbar>
    <transition name="dropdown">
      <f7-block v-if="!loading">
        <f7-button large round fill raised @click="resetPassword">Reset Password</f7-button>
      </f7-block>
    </transition>
    <transition name="dropdown">
      <f7-block class="text-center">An email will be sent to you with instructions.</f7-block>
    </transition>
    <transition name="dropdown">
      <f7-list v-if="!loading">
        <f7-list-button @click="goBack">Remember? Sign in</f7-list-button>
      </f7-list>
    </transition>
  </f7-page>
</template>
<script>
export default {
  data() {
    return {
      loading: false,
      email: "",
      staticLogo: require("@/assets/icons/relaxe.png")
    };
  },
  methods: {
    resetPassword() {
      if (this.email) {
        // if form have no errors
        this.loading = true;
        this.$store
          .dispatch("resetUserSecurity", {
            data: { email: this.email }
          })
          .then(() => {
            this.localNotify(
              "Passowrd Reset Successful!",
              `Password reset information has been sent to ${this.email}.`,
              "success-theme"
            );
            this.loading = false;
            this.goBack();
          })
          .catch(err => {
            this.loading = false;
            this.localNotify(
              "Passowrd Reset Failed!",
              `Unable to reset password for ${this.email}. ${err}`,
              "error-theme"
            );
          });
      } else {
        // form have errors
        this.localNotify(
          "Required Data Error",
          `Please provide a valid email.`,
          "error-theme"
        );
      }
    },
    localNotify(subtitle, text, colorTheme) {
      const self = this;
      const app = self.$f7;
      app.data.notification.show(app, subtitle, text, colorTheme);
    },
    goBack() {
      const self = this;
      const app = self.$f7;
      const router = self.$f7router;
      router.back();
    }
  }
};
</script>
