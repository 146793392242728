/*=========================================================================================
  File Name: getters.js
  Description: Vuex Store - getters
  ----------------------------------------------------------------------------------------
  Item Name: A.R.T Web Frame
  Version: 1.0.0
  Author: Asheori Reactive Technology
  Author URL: https://asheori.com
==========================================================================================*/


const getters = {

  // COMPONENT

  // Subscription Settings
  pricing: state => state.SubscriptionPricing,
  userSubscription: state => state.UserSubscription,

  // Candidate Wallet
  wallet: state => state.Wallet,

  // Candidate Invoices
  invoices: state => state.Invoices,
  invoice: state => state.Invoice,
  invoiceStatus: state => state.InvoiceStatus,

  // Candidate Bookings
  UserBookings: state => state.UserBookings.sort((a, b) => (a.confirm_status > b.confirm_status) ? 1 : -1),
  UnusedUserBookings: state => state.UserBookings.filter(booking => !booking.confirm_status).length,

  // Bookings (Pro)
  ProviderBookings: state => state.ProviderBookings.sort((a, b) => (a.confirm_status > b.confirm_status) ? 1 : -1),
  UnusedProviderBookings: state => state.ProviderBookings.filter(booking => !booking.confirm_status).length,

  // Providers
  Providers: state => state.Providers,


  // Payment Options & Providers
  paymentProviders: state => state.PaymentProviders,

  // Candidate Api Authorization
  authorization: state => state.authorization,

  // Candidate Authentication State
  isAuthenticated: state => state.isAuthenticated,

  // Candidate Profile
  candidateProfile: state => state.AppActiveUser, // Providers
  isProvider: state =>  state.AppActiveUser.provider.status,

  // Gallery
  gallery: state => state.Gallery,
}

export default getters