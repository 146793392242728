/*=========================================================================================
  File Name: state.js
  Description: Vuex Store - state
  ----------------------------------------------------------------------------------------
  Item Name: A.R.T Web Frame
  Version: 1.0.0
  Author: Asheori Reactive Technology
  Author URL: https://asheori.com
==========================================================================================*/

import { BaseUserState, BaseWalletState } from './base.state'

const state = {
  isAuthenticated: false,
  authorization: { token: '', identity: '' },
  AppActiveUser: BaseUserState,
  Wallet: BaseWalletState,
  UserBookings: [], 
  ProviderBookings: [],
  Providers: [],
  Gallery: [],
  UserSubscriptionLogs: [],
  UserSubscription: {},
  SubscriptionPricing: []
}

export default state