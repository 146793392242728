<template>
  <f7-page name="messaging">
    <f7-navbar title="Convesations"></f7-navbar>
    <f7-block-title :style="{marginTop: '5px', marginBottom: '10px'}">Conversations With...</f7-block-title>
    <f7-list media-list>
      <f7-list-item
        v-for="(member, index) in conversations"
        :key="index"
        title="Gbemisola"
        text="How are you doing today?"
      >
        <img
          :style="{borderRadius: '50%'}"
          slot="media"
          src="https://cdn.framework7.io/placeholder/people-160x160-1.jpg"
          width="40"
        >
        <f7-row  :style="{margin: 'auto', }">
          <f7-col>
            <f7-button
              outline
              @click="person='Debbie'"
              :style="{width: '80px'}"
              popup-open="#chat-popup"
            >
              <f7-icon f7="icon-chat_fill" size="20"></f7-icon>Chat
            </f7-button>
          </f7-col>
          <f7-col></f7-col>
          <f7-col  :style="{margin: 'auto'}">
            <f7-button
              color="red"
              outline
              @click="person='Debbie'"
              :style="{width: '100px'}"
              popup-open="#chat-popup"
            >
              <f7-icon f7="icon-trash" size="20"></f7-icon>Remove
            </f7-button>
          </f7-col>
        </f7-row>
      </f7-list-item>
    </f7-list>

    <!-- Popup -->
    <f7-popup id="chat-popup">
      <f7-view>
        <f7-page>
          <f7-navbar :title="'Chat with ' + person">
            <f7-nav-right>
              <f7-link popup-close>
                <f7-icon f7="arrow_left"></f7-icon>
              </f7-link>
            </f7-nav-right>
          </f7-navbar>
          <ChartPage></ChartPage>
        </f7-page>
      </f7-view>
    </f7-popup>
  </f7-page>
</template>
<script>
import ChartPage from "./chat";
export default {
  components: {
    ChartPage
  },
  data() {
    return {
      person: "",
      conversations: [1, 2, 3, 4, 5, 6, 6, 7]
    };
  }
};
</script>