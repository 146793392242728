<template>
  <f7-page no-toolbar no-navbar no-swipeback login-screen v-if="showScreen">
    <transition name="dropdown">
      <div class="text-center mt-40">
        <img class="logo" :src="staticLogo" />
      </div>
    </transition>
    <transition name="dropdown">
      <f7-list form>
        <f7-list-input
          floating-label
          outline
          label="Email"
          type="email"
          error-message="Please provide a valid email address!"
          required
          validate-on-blur
          :value="payload.user"
          @input="payload.user = $event.target.value"
        ></f7-list-input>
        <f7-list-input
          floating-label
          outline
          error-message="Please provide your password!"
          required
          validate-on-blur
          label="Password"
          type="password"
          :value="payload.password"
          @input="payload.password = $event.target.value"
        ></f7-list-input>
      </f7-list>
    </transition>
    <f7-progressbar v-if="loading" infinite color="multi"></f7-progressbar>
    <transition name="dropdown">
      <f7-block v-if="!loading">
        <f7-button large round fill raised @click="signIn">Sign In</f7-button>
      </f7-block>
    </transition>
    <transition name="dropdown">
      <f7-list v-if="!loading">
        <f7-list-button href="/register/">New? Sign up</f7-list-button>
        <f7-block-footer>Join the fastest growing personal massage provider booking platform in Nigeria.</f7-block-footer>
      </f7-list>
    </transition>
    <transition name="dropdown">
      <f7-list v-if="!loading">
        <f7-list-button color="orange" href="/forgot/">Forgot password?</f7-list-button>
      </f7-list>
    </transition>
  </f7-page>
</template>
<script>
import { AUTH } from "@/services/auth.service";
export default {
  data() {
    return {
      loading: false,
      showScreen: true,
      payload: {
        user: "",
        password: "",
        token: "",
        mode: 0
      },
      staticLogo: require("@/assets/icons/relaxe.png")
    };
  },
  methods: {
    signIn() {
      if (this.payload.user && this.payload.password) {
        this.loading = true;
        window.localStorage.setItem(
          "relaxe_access",
          JSON.stringify(this.payload)
        );
        this.getAccessToken();
      } else {
        this.localNotify(
          "Login Error",
          "Please provide email and password!",
          "error-theme"
        );
      }
    },
    getAccessToken() {
      AUTH.getAuthAcessToken()
        .then(accessTokenResponse => {
          this.payload.token = accessTokenResponse["result"]["token"];
          this.validateLogin(this.payload);
        })
        .catch(accessTokenError => {
          this.loading = false;
          this.payload.token = "";
          this.localNotify(
            "Token Error",
            `Unable to connect to token server. Please try again. ${accessTokenError}`,
            "error-theme"
          );
        });
    },
    validateLogin(login) {
      if (!login.user || !login.password || !login.token) {
        this.localNotify(
          "Security Error",
          `Invalid security credentials. Please try again.`,
          "error-theme"
        );
        this.loading = false;
      } else {
        this.authenticate(login);
      }
    },
    authenticate(login) {
      AUTH.authenticateUser(login)
        .then(authenticationResponse => {
          if (authenticationResponse["success"]) {
            this.initUserState(authenticationResponse);
            const self = this;
            const lastPage = window.sessionStorage.getItem("last_page");
            const router = self.$f7router;
            if (lastPage) {
              router.navigate(`/${lastPage}/`);
            } else {
              router.navigate("/home/");
            }
          } else {
            this.loading = false;
            this.localNotify(
              "Authentication Error",
              `Invalid email or password.`,
              "error-theme"
            );
          }
        })
        .catch(err => {
          this.loading = false;
          this.localNotify(
            "Token Error",
            `Unable to validate your account. Please try again. ${err}`,
            "error-theme"
          );
        });
    },

    initUserState(authenticationResponse) {
      window.localStorage.setItem("authState", "true");
      // Update User State
      this.$store.commit("UPDATE_USER_AUTHSTATE", {
        data: authenticationResponse["success"]
      });

      // Update User Authorization State
      this.$store.commit("UPDATE_USER_AUTHORIZATION", {
        data: {
          token: authenticationResponse["token"],
          identity: authenticationResponse["result"]["personal"]["identifier"]
        }
      });

      // Update Providers State
      this.$store.dispatch("getProviders", {
        data: {}
      });

      // Update User Bookings State
      this.$store.dispatch("getUserBookings", {
        data: authenticationResponse["result"]["personal"]["identifier"]
      });

      // Update Provider Bookings State
      this.$store.dispatch("getProviderBookings", {
        data: authenticationResponse["result"]["personal"]["identifier"]
      });

      // Update User Profile State
      this.$store.commit("UPDATE_USER_PROFILE", {
        data: authenticationResponse["result"]
      });

      // Update User Wallet State
      this.$store.dispatch("getWalletBalance", {
        data: {
          identifier: authenticationResponse["result"]["personal"]["identifier"]
        }
      });

      // Update User Transaction State
      this.$store.dispatch("getWalletTransactions", {
        data: {
          identifier: authenticationResponse["result"]["personal"]["identifier"]
        }
      });

      // Update User Gallery State
      this.$store.dispatch("getGallery", {
        data: authenticationResponse["result"]["personal"]["identifier"]
      });

      // Update User Subscription Settings
      this.$store.dispatch("getUserSubscription", {
        data: authenticationResponse["result"]["personal"]["email"]
      });

      // Update Subscription Pricing
      this.$store.dispatch("getSubscriptionPricing", {
        data: {}
      });
    },

    localNotify(subtitle, text, colorTheme) {
      const self = this;
      const app = self.$f7;
      app.data.notification.show(app, subtitle, text, colorTheme);
    }
  },
  created() {
    const storedPayload = window.localStorage.getItem("relaxe_access") || "";
    const payload = storedPayload ? JSON.parse(storedPayload) : {};
    if (payload.user && payload.password) {
      this.payload = payload;
      this.showScreen = false;
      this.getAccessToken();
    }
  }
};
</script>
