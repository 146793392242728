<template>
  <f7-page name="wallet" ptr @ptr:refresh="refresh">
    <!-- Top Navbar -->
    <f7-navbar>
      <f7-nav-title>My Subscription</f7-nav-title>
    </f7-navbar>
    <f7-progressbar v-if="loading" infinite color="multi"></f7-progressbar>
    <f7-block class="top-most mt-0">
      <div class="text-center p-20 mb-20">
        <span class="h1 mb-2 text-white">₦ {{ formatPrice(wallet.balance) }}</span>
        <div class="small-text-white">Account Balance</div>
        <div class="mt-10 p-10">
          <f7-button class="mb-10" large round fill raised @click="openFundingSheet">Fund Account</f7-button>
          <f7-button color="green" large round fill raised @click="openSubscribingSheet">Subscribe</f7-button>
          <div class="small-text-white mt-10">
            Subscription ends
            <timeago :datetime="userSubscription.end_date"></timeago>
          </div>
        </div>
      </div>
    </f7-block>

    <!-- No Bookings -->
    <div v-if="wallet.transactions.length === 0" class="p-20 text-center mt-40">
      <img :src="staticImage" alt=":-(" class="asset-img" />
      <div class="p-20 text-center text-white">No Relaxe balance</div>
    </div>
    <!-- /No Bookings -->

    <!-- Transactions -->
    <div class="data-table">
      <f7-card v-for="(transaction, index) in wallet.transactions" :key="index" class="p-10">
        <div>{{ transaction.note ? transaction.note : 'Undisclosed' }}</div>
        <div>
          <small class="text-primary">{{ transaction.created | date }}</small>
        </div>
        <div class="mb-30">
          <div class="float-left">
            <span class="h3">
              ₦
              {{ formatPrice(transaction.amount/100) }}
            </span>
          </div>
          <div class="float-right">
            <f7-chip
              :text="transaction.transaction"
              :color="transaction.transaction === 'Credit' ? 'green' : 'orange'"
            ></f7-chip>
          </div>
        </div>
      </f7-card>
    </div>
    <!-- /Transactions -->

    <!-- Funding Sheet -->
    <f7-sheet class="funding-sheet" :opened="sheetOpened" @sheet:closed="sheetOpened = false">
      <f7-toolbar class="primary-theme-bg top-curve">
        <div class="left p-10 text-white">Fund Account</div>
        <div class="right">
          <f7-link
            color="white"
            icon-ios="f7:arrow_down"
            icon-aurora="f7:arrow_down"
            icon-md="f7:arrow_down"
            sheet-close
          >Close</f7-link>
        </div>
      </f7-toolbar>
      <!-- Scrollable sheet content -->
      <f7-page-content>
        <transition name="dropdown">
          <f7-list class="mt-0 bg-none">
            <f7-list-input
              class="bg-none"
              floating-label
              outline
              clear-button
              label="Amount (Naira)"
              type="number"
              error-message="Enter numbers only please!"
              required
              validate
              pattern="[0-9]*"
              :value="payload.amount"
              @input="payload.amount = $event.target.value"
            ></f7-list-input>
            <f7-block>
              <f7-button large round fill raised @click="payWithPaystack">
                <f7-icon ios="f7:card" aurora="f7:card" md="f7:card"></f7-icon>&nbsp;Make Payment
              </f7-button>
            </f7-block>
          </f7-list>
        </transition>
      </f7-page-content>
    </f7-sheet>
    <!-- /Funding Sheet -->

    <!-- Subscribing Sheet -->
    <f7-sheet
      class="subscribing-sheet"
      :opened="subscriptionSheetOpened"
      @sheet:closed="subscriptionSheetOpened = false"
    >
      <f7-toolbar class="primary-theme-bg top-curve">
        <div class="left p-10 text-white">Add Account Subscription</div>
        <div class="right">
          <f7-link
            color="white"
            icon-ios="f7:arrow_down"
            icon-aurora="f7:arrow_down"
            icon-md="f7:arrow_down"
            sheet-close
          >Close</f7-link>
        </div>
      </f7-toolbar>
      <!-- Scrollable sheet content -->
      <f7-page-content>
        <transition name="dropdown">
          <f7-list class="mt-0 bg-none">
            <f7-list-input
              class="bg-none"
              label="Select Duration"
              type="select"
              placeholder="Please choose..."
              :value="subscriptionPayload.duration"
              @input="subscriptionPayload.duration = $event.target.value"
            >
              <option
                v-for="(item, index) in pricing"
                :key="index"
                :value="item.duration"
              >{{ item.title }}</option>
            </f7-list-input>
            <f7-block>
              <f7-button large round fill raised @click="payWithWallet">
                <f7-icon ios="f7:ticket" aurora="f7:ticket" md="f7:ticket"></f7-icon>&nbsp;Add Subscription
              </f7-button>
            </f7-block>
          </f7-list>
        </transition>
      </f7-page-content>
    </f7-sheet>
    <!-- /Subscribing Sheet -->
  </f7-page>
</template>
<script>
import { mapGetters } from "vuex";
import { WALLET } from "@/services/wallet.service";
import { helper, utils } from "@/services/helper";
const dateFilter = value => {
  return new Date(value).toDateString();
};

const trimText = value => {
  if (value.length <= 20) {
    return value;
  }
  return value.substring(0, 20) + "...";
};
export default {
  filters: {
    date: dateFilter,
    trim: trimText
  },
  data() {
    return {
      loading: false,
      sheetOpened: false,
      subscriptionSheetOpened: false,
      payload: {
        amount: null
      },
      subscriptionPayload: {
        owner: null,
        user: {},
        amount: 5000,
        start_date: null,
        end_date: null,
        duration: 30
      },
      staticImage: require("@/assets/icons/wallet.png")
    };
  },
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    refresh(event, done) {
      this.loading = true;
      setTimeout(() => {
        if (
          this.candidateProfile.personal &&
          this.candidateProfile.personal.identifier
        ) {
          this.getWallet(this.candidateProfile.personal.identifier);
          this.getTransactions(this.candidateProfile.personal.identifier);
          done();
        }
      }, 500);
    },

    getWallet(identifier) {
      this.$store
        .dispatch("getWalletBalance", {
          data: { identifier }
        })
        .then()
        .catch(err => {
          this.localNotify(
            "Balance Notification",
            `Unable to get wallet balance. ${err}`,
            "error-theme"
          );
        });
    },

    getTransactions(identifier) {
      this.loading = true;
      this.$store
        .dispatch("getWalletTransactions", {
          data: { identifier }
        })
        .then(() => {
          this.loading = false;
        })
        .catch(err => {
          this.loading = false;
          this.localNotify(
            "Network Error",
            `Unable to get wallet balance. ${err}`,
            "error-theme"
          );
        });
    },

    payWithPaystack() {
      if (this.payload.amount) {
        this.closeFundingSheet();
        const code = JSON.parse(sessionStorage.getItem("relaxe_user"))
          .authorization;
        // eslint-disable-next-line
        const handler = PaystackPop.setup({
          key: "pk_live_ef32eb96f12fc30783e3057220e4fb50d41fc5f1",
          email: this.candidateProfile.personal.email,
          amount: this.payload.amount * 100,
          ref: helper.generateIdentifier,
          metadata: {
            custom_fields: [
              {
                firstname: this.candidateProfile.personal.firstname,
                lastname: this.candidateProfile.personal.lastname,
                identifier: this.candidateProfile.personal.identifier,
                mobile: this.candidateProfile.personal.mobile,
                email: this.candidateProfile.personal.email,
                one_signal_id:
                  this.candidateProfile.personal.one_signal_id || "",
                note: "Wallet funding",
                token: code.token,
                identity: code.identity
              }
            ]
          },
          callback: response => {
            this.verifyPayment(response.reference);
          },
          onClose: () => {
            this.payload.amount = null;
            this.closeFundingSheet();
          }
        });
        handler.openIframe();
      } else {
        this.localNotify(
          "Funding Amount Error",
          `Please provide amount of hearts to fund.`,
          "error-theme"
        );
      }
    },

    payWithWallet() {
      this.loading = true;
      const walletPaymentPayload = {};
      const personal = this.candidateProfile.personal;
      const duration = this.subscriptionPayload.duration;
      const pricing = utils.getPricing(this.pricing, duration);
      const startDate = utils.getStartDate(this.userSubscription.end_date);

      // Get Subscription Start Date & End Date
      this.subscriptionPayload.start_date = startDate;
      this.subscriptionPayload.end_date = utils.addDays(startDate, duration);

      // Setup Wallet Payment Payload
      walletPaymentPayload.sender = personal.identifier;
      walletPaymentPayload.receiver =
        "891d7d81b5e177cebe8ae4b2d12957d31562452643830";
      walletPaymentPayload.amount = pricing.price * 100;
      walletPaymentPayload.note = `${pricing.title} Relaxe Subscription.`;
      walletPaymentPayload.subscription = this.subscriptionPayload;
      walletPaymentPayload.subscription.owner =
        "891d7d81b5e177cebe8ae4b2d12957d31562452643830";
      walletPaymentPayload.subscription.amount = pricing.price;
      walletPaymentPayload.subscription.note = `${pricing.title} Relaxe Subscription.`;
      walletPaymentPayload.participants = {
        push_data: {
          user_push_id: this.payload.user_one_signal_id || "",
          merchant_push_id: this.payload.provider_one_signal_id || ""
        },
        merchant: {
          firstname: "Relaxe",
          lastname: "App",
          email: "finance@asheori.com",
          mobile: "07060694202"
        },
        customer: {
          firstname: personal.firstname,
          lastname: personal.lastname,
          email: personal.email,
          mobile: personal.mobile
        }
      };

      // Check if user account has sufficient balance
      this.getBalanceWorth(walletPaymentPayload.sender)
        .then(response => {
          if (response["walletBalance"] >= pricing.price) {
            // If it does, make payment
            WALLET.makePayment(walletPaymentPayload)
              .then(makePaymentResponse => {
                // Check if payment was successfully executed
                if (makePaymentResponse["success"]) {
                  this.paymentSuccessful = true;

                  // Update User Ledger State
                  this.ledgerStateInit(
                    walletPaymentPayload.sender,
                    personal.email
                  );

                  // Stoping loading indicator, and close Subscribing Sheet
                  this.loading = false;
                  this.closeSubscribingSheet();

                  // Notify User of Success
                  this.localNotify(
                    "Subscription Successful",
                    `${duration} ${
                      duration <= 1 ? "day" : "days"
                    } was added successfully to your subscription.`,
                    "success-theme"
                  );
                } else {
                  // If payment not successful, notify the user
                  // Add Error log API (future update)
                  this.loading = false;
                  this.localNotify(
                    "Payment Error",
                    `${makePaymentResponse["result"]["message"]}`,
                    "error-theme"
                  );
                }
              })
              .catch(makePaymentError => {
                // Catch error that may arise during this transaction, and notify the user
                // Add Error/Activity Log API (Future update across catch block)
                this.loading = false;
                this.localNotify(
                  "Payment Error",
                  `Unable to make payment. Try again. ${makePaymentError}`,
                  "error-theme"
                );
              });
          } else {
            // If it doesn't, notify the user
            // Add Error/Activity Log API (Future update across catch block)
            this.loading = false;
            this.localNotify(
              "Insufficient Fund",
              `You don't have enough funds to make this subscription. Please fund your account and subscribe.`,
              "error-theme"
            );
          }
        })
        .catch(err => {
          // Catch error that may arise during this transaction, and notify the user
          // Add Error?Activity Log API (Future update across catch block)
          this.loading = false;
          this.localNotify(
            "Wallet Error",
            `Unable to get wallet balance. Try again. ${err}`,
            "error-theme"
          );
        });
    },

    getBalanceWorth(identifier) {
      return new Promise((resolve, reject) => {
        WALLET.balance(identifier)
          .then(balanceResponse => {
            const walletBalance = balanceResponse["result"]["balance"] / 100;
            resolve({ walletBalance });
          })
          .catch(responseError => reject(responseError));
      });
    },

    verifyPayment(transaction) {
      this.loading = true;
      WALLET.verifyPayment(transaction)
        .then(verificationResponse => {
          this.loading = false;
          if (verificationResponse.result.body.data.status === "success") {
            this.localNotify(
              "Funded Successfully!",
              `Your wallet was funded successfully with ₦${this.formatPrice(
                this.payload.amount
              )}`,
              "success-theme"
            );
            this.getWallet(this.candidateProfile.personal.identifier);
            this.getTransactions(this.candidateProfile.personal.identifier);
            this.payload.amount = null;
          } else {
            this.localNotify(
              "Funding Error",
              `Payment unsuccessful, please try again.`,
              "error-theme"
            );
          }
        })
        .catch(verificationError => {
          // this.loading = false;
          this.localNotify(
            "Payment Verification Error",
            `Payment verification unsuccessful, please try again. Error: ${verificationError}`,
            "error-theme"
          );
        });
    },

    ledgerStateInit(identifier, email) {
      // Update User Wallet State
      this.$store.dispatch("getWalletBalance", {
        data: {
          identifier
        }
      });

      // Update User Transaction State
      this.$store.dispatch("getWalletTransactions", {
        data: {
          identifier
        }
      });

      // Update User Subscription Settings
      this.$store.dispatch("getUserSubscription", {
        data: email
      });
    },

    openFundingSheet() {
      this.closeSubscribingSheet();
      const self = this;
      self.$f7.sheet.open(".funding-sheet");
    },
    closeFundingSheet() {
      // Close sheet
      const self = this;
      self.$f7.sheet.close(".funding-sheet");
    },

    openSubscribingSheet() {
      this.closeFundingSheet();
      this.subscriptionPayload.user = {
        firstname: this.candidateProfile.personal.firstname,
        lastname: this.candidateProfile.personal.lastname,
        email: this.candidateProfile.personal.email,
        mobile: this.candidateProfile.personal.mobile
      };
      const self = this;
      self.$f7.sheet.open(".subscribing-sheet");
    },

    closeSubscribingSheet() {
      // Close sheet
      const self = this;
      self.$f7.sheet.close(".subscribing-sheet");
    },

    onPageBeforeOut() {
      const self = this;
      // Close opened sheets on page out
      self.$f7.sheet.close();
    },

    onPageBeforeRemove() {
      const self = this;
      // Destroy sheet modal when page removed
      if (self.sheet) self.sheet.destroy();
    },

    localNotify(subtitle, text, colorTheme) {
      const self = this;
      const app = self.$f7;
      app.data.notification.show(app, subtitle, text, colorTheme);
    }
  },
  computed: {
    ...mapGetters(["candidateProfile", "wallet", "pricing", "userSubscription"])
  }
};
</script>