export const BaseUserState = {
    _id: null,
    photo: {
        url: ''
    },
    personal: {
        identifier: '',
        firstname: '',
        lastname: '',
        email: '',
        mobile: '',
        gender: '',
        about: '',
        age_group: '',
        marital_status: '',
        occupation_status: '',
        education_level: '',
        household_size: null,
        household_income: '',
        religious_belief: '',
        spoken_languages: []
    },
    earning: {
        fee: 0,
        duration: 0,
    },
    public: {
        facebook: '',
        instagram: '',
        twitter: ''
    },
    location: {
        city: '',
        state: '',
        country: 'Nigeria'
    },
    financial: {},
    references: [],
    interests: [],
    preferences: [],
    brands: [],
    verify: {
        pin: 0,
        email: false,
        mobile: false
    },
    document_status: 0,
    created: '',
    last_seen: '',
    online: false,
    provider: {
        status: false,
        city: '',
    },
    notification: {
        email: false,
        mobile: false
    },
    one_signal_id: ''
}

export const BaseWalletState = {
    balance: 0,
    transactions: [],
    active: false
}
