/*=========================================================================================
  File Name: registration.services.js
  Description: Registration Services
  ----------------------------------------------------------------------------------------
  Item Name: A.R.T Web Frame
  Version: 1.0.0
  Author: Asheori Reactive Technology
  Author URL: https://asheori.com
==========================================================================================*/
import * as axios from 'axios';
import {
  store
} from "./service.store";

export const REG = {
  // ADD NEW USER
  addUser: (user, token) => {
    const HTTPX = axios.create({
      baseURL: `${store.api.config.registration_endpoint}/`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': store.api.config.DEFAULT_AUTHENTICATION_TOKEN,
        'token': token
      }
    });
    return new Promise((resolve, reject) => {
      HTTPX.post(store.api.config.REGISTER_USER, user)
        .then(addResponse => {
          resolve(addResponse.data);
        }).catch(errResponse => {
          reject(errResponse);
        });
    });
  },

   // ADD QUIKC USER
   addQuickUser: (user, token) => {
    const HTTPX = axios.create({
      baseURL: `${store.api.config.registration_endpoint}/`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': store.api.config.DEFAULT_AUTHENTICATION_TOKEN,
        'token': token
      }
    });
    return new Promise((resolve, reject) => {
      HTTPX.post(store.api.config.REGISTER_QUICK_USER, user)
        .then(addResponse => {
          resolve(addResponse.data);
        }).catch(errResponse => {
          reject(errResponse);
        });
    });
  },

  validateEmailPIN: (payload) => {
    const HTTPX = axios.create({
      baseURL: `${store.api.config.registration_endpoint}/`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': store.api.config.DEFAULT_AUTHENTICATION_TOKEN,
        'token': payload.token
      }
    });
    return new Promise((resolve, reject) => {
      HTTPX.post(store.api.config.VERIFY_EMAIL_PIN, payload)
        .then(addResponse => {
          resolve(addResponse.data);
        }).catch(errResponse => {
          reject(errResponse);
        });
    });
  },

  validateMobilePIN: (payload) => {
    const HTTPX = axios.create({
      baseURL: `${store.api.config.registration_endpoint}/`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': store.api.config.DEFAULT_AUTHENTICATION_TOKEN,
        'token': payload.token
      }
    });
    return new Promise((resolve, reject) => {
      HTTPX.post(store.api.config.VERIFY_MOBILE_PIN, payload)
        .then(addResponse => {
          resolve(addResponse.data);
        }).catch(errResponse => {
          reject(errResponse);
        });
    });
  }

}
