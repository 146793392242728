export const helper = {
  generateIdentifier: () => {
    const now = new Date().getTime().toString();
    const charset = 'abcdef0123456789';
    let text = '';
    const len = 32;
    for (let i = 0; i < len; i++) {
      text += charset.charAt(Math.floor(Math.random() * charset.length));
    }
    return text + now;
  },
}

export const errorHelper = (err) => {
  if (err.status === 401) {
    this.$vs.notify({
      color: "warning",
      title: "Notification",
      text: `Session expired. Please login again`
    });
    this.$store.commit("LOGOUT", { val: false });
    this.$router.push("/");
  } else {
    this.$vs.notify({
      color: "warning",
      title: "Notification",
      text: `Network error. Please try again`
    });
  }
}

export const utils = {

  getPricing(pricing, duration) {
    return pricing.filter(
      price => price.duration === parseInt(duration)
    )[0];
  },

  getStartDate: (endDate) => {
    const _endDate = new Date(endDate);
    const today = new Date();
    if(_endDate > today) {
      return endDate;
    } else {
      return today;
    }
  },

  addDays: (date, days) => {
    let result = new Date(date);
    const _days = parseInt(days);
    result.setDate(result.getDate() + _days);
    return new Date(result);
  },

  formatPrice: (value) => {
    let val = (value / 1).toFixed(2).replace(",", ".");
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },

  formatTime: (value) => {
    let val = value;
    if (isNaN(val)) {
      return val;
    }
    if (val < 60) {
      return val + "mins";
    }
    if ((val / 60) === 1) {
      return val / 60 + "hr";
    }
    return (val / 60) + "hrs";
  },

  bgColor: (item) => {
    switch (item) {
      case "Chair":
        return "green";
      case "Couples":
        return "pink";
      case "Deep Tissue":
        return "yellow";
      case "Nuru":
        return "purple";
      case "Prenatal":
        return "orange";
      case "Swedish":
        return "blue";
      default:
        return "purple";
    }
  },
}