export const LEGAL = {
  terms: `<div class="text-white">
  <b>Background</b>

<ol>
<li style="font-weight: 400;"><span style="font-weight: 400;">  Relaxé is a technology platform that connects you and a massage provider.</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">The Platform enables you to request and book a massage provider to provide a Massage Service. </span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Each massage provider is in the business of providing Massage Services using the Platform to connect with you and other users of the Platform. </span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">The Platform is intended solely for persons who are 18 years of age or older. By accessing or using the Platform you represent and warrant that you are 18 or older.  </span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">These Terms govern your use of the Platform including the software, Mobile Application and the Website, and forms a binding contractual agreement between you and Relaxé, under which Relaxé agrees to provide you with access to the Platform and you agree to the obligations in these Terms.  </span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">For that reason these Terms are important and you should ensure that the Terms are read carefully.  By using the Platform you acknowledge and agree that you have had sufficient chance to read and understand the Terms and agrees to be bound by them. If you do not agree to the Terms, you must not use the Platform in any way.  </span></li>
</ol>
</li>
</ol>
<p>&nbsp;</p>
<b>Definitions</b>
<p><span style="font-weight: 400;">In these Terms:</span></p>
<p><b>Cancel the Booking </b><span style="font-weight: 400;">means a decision made by the massage provider or you to cancel a Booking. The massage provider can no longer attend by clicking the &#8220;Cancel Booking&#8221; function on the Platform.</span></p>
<p><b>  Relaxé or we or us </b><span style="font-weight: 400;">means Luxury et al. </span></p>
<p><b>Booking </b><span style="font-weight: 400;">means an accepted Booking Request for the provision of the Massage Service which has been processed on the Platform and communicated to the massage provider via the Platform.</span></p>
<p><b>Booking Confirmation </b><span style="font-weight: 400;">is a Notification sent to you confirming a Booking.</span></p>
<p><b>Booking Request</b><span style="font-weight: 400;"> means a booking request made by you through the Platform.</span></p>
<p><b>Booking Request Details </b><span style="font-weight: 400;">means the details you have entered into the Booking Request including the details of the Property, the requested time and date of the Massage Service, the type of Massage Service required, the preferred gender of the massage provider, preferred Massage Time and your contact details.</span></p>
<p><b>Booking System </b><span style="font-weight: 400;">means the online booking system on the Platform used to make a Booking Request in accordance with clause 6 of this document. </span></p>
<p><b>  Relaxé Network </b><span style="font-weight: 400;">means the database of massage provider located on the Platform. </span></p>
<p><b>Dispute </b><span style="font-weight: 400;">means a dispute, controversy or claim arising out of or in connection with these Terms. </span></p>
<p><b>Intellectual Property Rights</b><span style="font-weight: 400;"> means all present and future rights anywhere in the world conferred by statute, common law or equity in or in relation to any copyright, trademarks, designs, patents, circuit layouts, business and domain names, inventions, confidential information, trade secrets and other results of intellectual activity in the industrial, commercial, scientific, literary or artistic fields.</span></p>
<p><b>Losses </b><span style="font-weight: 400;">mean any and all actions, claims, proceedings, judgments, demands, liabilities, losses, costs (including reasonable legal costs), expenses, penalties, fines and damages which may be brought or awarded against Relaxé or suffered, incurred or paid by Relaxé (howsoever arising) in respect of, arising out of or in connection with the Massage Services provided by that massage provider (or any of the massage providers&#8217; employees or agents), a violation of any law by the massage provider, or a breach of the terms, conditions, covenants, warranties and representations (express or implied) contained in these Terms on the part of the massage provider (or any of the massage provider&#8217;s employees or agents) and include loss of data, interruption of business or any consequential or incidental damages.</span></p>
<p><b>Massage Equipment </b><span style="font-weight: 400;">means equipment required by the massage provider to complete the Massage Service, such equipment includes but not limited to, a massage table massage oils, relaxing music and clean towels.</span></p>
<p><b>Massage Service Fee </b><span style="font-weight: 400;">means the amount on the Platform as being payable by you on the acceptance of a Booking Request which can be subject to change if you extend the Booking Time of the Massage Services.</span></p>
<p><b>Massage Services</b><span style="font-weight: 400;"> means the services provided as part of massage therapy, beauty therapy and other therapeutic treatments, including but not limited to remedial massage, sports massage, nuru massage, swedish/relaxation massage and pregnancy massage provided by a massage provider in accordance with these Terms.</span></p>
<p><b>Massage Time </b><span style="font-weight: 400;">means the length of the Massage Services requested by you in the Booking Request.</span></p>
<p><b>Mobile Application</b><span style="font-weight: 400;"> means the apple or android app titled ‘Relaxé’ owned by Luxury et al.</span></p>
<p><b>Notification </b><span style="font-weight: 400;">means an electronic notification sent to the email address or mobile phone number registered on the User Profile.</span></p>
<p><b>Onboarding </b><span style="font-weight: 400;">means the registration process on the Platform.</span></p>
<p><b>Payment Method </b><span style="font-weight: 400;">means</span> <span style="font-weight: 400;">a direct bank transfer, or Paystack. </span></p>
<p><b>Platform</b><span style="font-weight: 400;"> means the suite of software applications including the Support that enables you to book a massage provider offered via the Mobile Application or the Website.</span></p>
<p><b>Property </b><span style="font-weight: 400;">means the location chosen by you for the massage provider to provide the Massage Service.</span></p>
<p><b>Rebooking Request</b><span style="font-weight: 400;"> means a Booking Request made by you offered to a Specific massage provider.</span></p>
<p><b>Site Content</b><span style="font-weight: 400;"> means all material, content and information made available on the Platform and/or Relaxé’s official social media accounts, including but not limited to feedback, reviews and ratings provided by you, users of the Platform or Website and massage providers, any written text, graphics, images, photographs, logos, trademarks, audio material, video material and any other forms of expression.</span></p>
<p><b>Specific massage provider </b><span style="font-weight: 400;">means a specific massage provider you have used before as requested in a Rebooking Request. </span></p>
<p><b>Support</b><span style="font-weight: 400;"> means the technical support, resources and information, payment and maintenance services for the Platform and updates to the Platform software that Relaxé may make generally available to you and the massage provider.  </span></p>
<p><b>Terms </b><span style="font-weight: 400;">means the terms and conditions set out in this document.  </span></p>
<p><b>Tax</b><span style="font-weight: 400;"> means income tax, payroll tax, stamp duty, GST, superannuation and all other applicable taxes.</span></p>
<p><b>massage provider</b><span style="font-weight: 400;"> means an individual (including a person trading under a business name), approved by Relaxé to use the Platform.</span></p>
<p><b>Third Party Site </b><span style="font-weight: 400;">means any hyperlink or other redirection tools taking the User to other websites operated by third parties that appear on the Website or Mobile applications.</span></p>
<p><b>User or you or your </b><span style="font-weight: 400;">means a person who uses the Platform.</span></p>
<p><b>User Profile </b><span style="font-weight: 400;">means a profile created on the Platform by you and includes your name, address, phone number, gender and credit card details. </span></p>
<p><b>Website</b><span style="font-weight: 400;"> means the website at the domain name https://relaxe.com.ng/ (or such other domain name as Relaxé may use in relation to the Business from time to time).</span></p>
<p>&nbsp;</p>
<b>Terms of Use</b>
<span style="font-weight: 400;">  Relaxé grants to you a revocable, non-exclusive, non-transferrable licence to:</span>

<ol>
<li style="font-weight: 400;"><span style="font-weight: 400;">use and access the Platform on your personal device solely for the permitted use of making Booking Requests on these Terms. </span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">view the Website and the Mobile Application;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">print pages from the Website and the Mobile Application in its original form; and</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">download any material from the Website and Mobile Application for caching purposes only.</span></li>
</ol>
<span style="font-weight: 400;">You acknowledge and agree that:</span>
<ol>
<li style="font-weight: 400;"><span style="font-weight: 400;">  Relaxé does not employ the massage providers;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">  Relaxé does not offer to or provide, perform or conduct Massage Services nor does it act as an agent or broker for the massage provider;  </span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">  Relaxé does not participate in the interaction between you and the massage provider except to the extent necessary to provide the Platform and the Support to facilitate the interaction, including the invoicing and processing of the Massage Fee from behalf of massage providers;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">any Third Party Sites are not controlled by Relaxé and do not form part of the Platform. You agree that you will not hold Relaxé liable or in any way accountable for anything that occurs on any Third Party Site; and </span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">you enter into these Terms and use the Platform entirely at your own risk.</span></li>
</ol>
<p>&nbsp;</p>
<b>Term </b>
<ol>
<li style="font-weight: 400;"><span style="font-weight: 400;">These Terms commence on the date you access and use the Platform.</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">  Relaxé periodically reviews the Terms and reserves the right to amend the Terms, without any notice to you, by updating this document. You should review this document, as available on the Website and Mobile Application, regularly as any changes to the Terms take immediate effect from the date of the publication on the Website and Mobile Application. The continued access and use of the Platform constitutes your consent to be bound by these Terms, as amended from time to time.</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">These Terms continue in force unless and until terminated in accordance with these Terms.</span></li>
</ol>
<p>&nbsp;</p>

<b>Personal Information &amp; User Profile</b>
<ol>
<li style="font-weight: 400;"><span style="font-weight: 400;">To make a Booking Request, you must create and register a User Profile in the manner described on the Platform.  </span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">You will be issued with a username and password which will be linked to your User Profile. The username and password are personal to you and are not transferable. </span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">You are responsible for all information posted on or transmitted via the Booking System by anyone using your username and password and any payments due for the Massage Service acquired through the Booking System by anyone using your username and password. You should notify us of any breach of security of your username and password immediately.</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">You confirm that you are at least 18 years of age and possess the legal authority to use the Booking System in accordance with these Terms.</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">You acknowledge and agree that all information supplied by you in using the Platforms and the Booking System will be true and accurate at all times.</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">  Relaxé may delete or block User Profiles at its own discretion. Blocked users are prohibited from setting up a new User Profile.</span></li>
</ol>

<p>&nbsp;</p>
<b>Booking System</b>
<ol>
<li style="font-weight: 400;"><span style="font-weight: 400;">You must use the Booking System to make a Booking Request.</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Once you have submitted the Booking Request Details on the Platform, the Booking Request Details will be made available on the Relaxé Network where an available massage provider can view and then accept the Booking Request.</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">On acceptance of a Booking Request, the Platform will:</span>
<ol>
<li style="font-weight: 400;"><span style="font-weight: 400;">send a Notification confirming your Booking;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">deduct the Massage Fee from the Payment Method registered on the User Profile; and </span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">send, on behalf of the massage provider, a tax invoice/receipt to your email address registered on the User Profile.</span></li>
</ol>
</li>
</ol>

<li style="font-weight: 400;"><span style="font-weight: 400;">The Massage Fee is the amount specified on the Platform for the Massage Service and is determined by the Massage Time and location of the Property.  </span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">You acknowledge that the Massage Fee may be subject to change from time to time and Relaxé is under no obligation to notify you of the change.  </span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">The Platform provides you with a function to make a Rebooking Request for a Specific massage provider however this is subject to the availability of the Specific massage provider. </span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">If the Specific massage provider is unavailable, your Rebooking Request will convert into an ordinary Booking Request and processed in accordance with this clause 6. </span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">If the accepted massage provider Abandons the Booking, the Platform will locate an alternative massage provider for the Booking.  If an alternative massage provider cannot be arranged, you will receive a Notification notifying you that there are no available massage provider and a new booking time and date is to be made on the Platform. </span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">You will receive a refund of the Massage Fee if a new booking time and date cannot be made on the Platform. </span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">On some occasions, if and when, deemed necessary by Relaxé, Relaxé may accept booking requests over the phone. When the Booking Request is received over the phone, the payment by the client must be made in full, with a valid Payment Method on the User Profile or over the phone, and within one (1) hour after the massage provider has accepted the Booking Request.  Failing to do so may result in Relaxé cancelling the Booking.</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">You acknowledge and agree that:</span>

<ol>
<li style="font-weight: 400;"><span style="font-weight: 400;">  Relaxé is not a party to any agreement made between you and the massage provider; </span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">  Relaxé has no control over and is not responsible for the actions or omissions of the massage provider or any other users of the Platform;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">you are not permitted to engage the Massage Service of a massage provider other than through the use of the Platform;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">the Platform is to be used by you for the purpose of making a Booking Request, and for no other purposes;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">any speculative, false or fraudulent Booking Request is prohibited;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">the Booking System will only be used to make legitimate Bookings for you or another person for whom you are legally authorised to act; and </span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">abuse of the Booking System may result in you being denied access to the Platform.</span></li>
</ol>
</li>


<span style="font-weight: 400;">  Relaxé is entitled, at its discretion:</span>
<ol>
<li style="font-weight: 400;"><span style="font-weight: 400;">to stop making the Platform available to you without notice, and without providing any reason; and </span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">to add, change or withdraw functions available on the Platform.</span></li>
</ol>

<p>&nbsp;</p>
<b>Payment</b>

<ol>
<li style="font-weight: 400;"><span style="font-weight: 400;">You agree that at the time of your Booking, your have an active subscription. </span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Upon the acceptance of a Booking Request by a massage provider, Relaxé will notify the massage provider, and provide your with contact access to the massage provider.</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">  Relaxé does not hold or collect the Massage Fee on behalf of the massage provider.</span>
</ol>

<p>&nbsp;</p>
<b>Massage Service</b>
<span style="font-weight: 400;">  Relaxé does not make any representations as to:</span>

<ol>
<li style="font-weight: 400;"><span style="font-weight: 400;">the safety or quality of the Massage Service provided by the massage provider; or </span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">the safety or quality of the Massage Equipment used by the massage provider during the Massage Service; or</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">the condition or description of any products used by the massage provider during the Massage Service.</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Any issues regarding safety, quality, condition or description will be sent to Relaxé through the Platform and passed onto the massage provider that completed the Booking. </span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">The Massage Services offered by the massage providers on the Platform are strictly therapeutic. Any suggestions or comments by you that are in sexual nature before, during or after the Booking, will result in termination of the Booking and suspension of your account in accordance with clause 10 of this document with no refund, and will be reported to authorities as a matter of sexual harassment.</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">  Relaxé does not give any warranty or make any representation that the massage providers have approved qualifications to be a massage massage provider however each massage provider must compete an approved Onboarding process to receive access to the Platform and can be removed from the Relaxé Network at any time.</span></li>
</ol>

<b>Termination </b>
<ol>
<li style="font-weight: 400;"><span style="font-weight: 400;">  Relaxé may at its discretion terminate your use of, or access to, the Platform at any time. </span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">If your use of the Platform is terminated:</span>

<ol>
<li style="font-weight: 400;"><span style="font-weight: 400;">you will receive a Notification confirming your termination;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">you will no longer be authorised to access the Platform or use any other services or products owned by Luxury et al;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">the email address you used to register with the Platform or any other email address you possess will not be authorised to access the Platform or any other services or products owned by Relaxé;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">you will continue to be subject to and bound by all restrictions imposed on you by the Terms; and</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">all licences granted to you and all disclaimers by Relaxé and limitations of Relaxé’ liability set out in the Terms or elsewhere on the Platforms will survive termination.</span></li>
</ol>
</li>
</ol>


<p>&nbsp;</p>
<b>Dispute Resolution </b>

<p><span style="font-weight: 400;">You acknowledge and agree that:</span></p>

<ol>
<li style="font-weight: 400;"><span style="font-weight: 400;">any Dispute between you and a massage provider concerning the Massage Service carried out by the massage provider are directly between you and the massage provider;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">  Relaxé is not a party to any Dispute between you and a massage provider;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">  Relaxé is under no obligation to mediate any Dispute between you and the massage provider however Relaxé will endeavour to assist where necessary to mediate any Dispute between you and a massage provider;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">you will be required to pay or reimburse any legal fees incurred by Relaxé as a result of having to mediate any Dispute between you and a massage provider. </span></li>
</ol>

<b>Security of Payment</b>

<ol>
<li style="font-weight: 400;"><span style="font-weight: 400;">We have taken all practical steps from both a technical and systems perspective to ensure that the payment details registered on the User Profile is well protected. </span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">A secure payment gateway (Paystack) is used to process all payment transactions. </span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">  Relaxé does not give any warranty or make any representation regarding the strength or effectiveness of the secure payment gateway and is not responsible for events arising from unauthorised access to your information.</span></li>
</ol>

<p>&nbsp;</p>

<b>Privacy</b>

<ol>
<li style="font-weight: 400;"><span style="font-weight: 400;">In this clause Personal Information means information or an opinion (including information or an opinion forming part of a database) whether true or not, and whether recorded in a material form or not, about an individual whose identity is apparent or can reasonably ascertained, from the information or opinion.</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">The Platform collects and stores Personal Information you enter into the User Profile. Personal Information collected on the Platform will be maintained in accordance with our Privacy Policy available on the </span><a href="https://www.relaxeapp.com/privacy"><span style="font-weight: 400;">www.relaxeapp.com/privacy</span></a><span style="font-weight: 400;">.</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">You agree that the Platform will disclose your Personal Information, including your name and address to the massage provider in order for the massage provider to complete the Booking. </span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">You must abide by all relevant Australian Privacy Principles set out in the Privacy Act 1998 in their collection, use, disclosure and storage of Personal Information.</span></li>
</ol>
<b>Intellectual Property</b>

<ol>
<li style="font-weight: 400;"><span style="font-weight: 400;">Nothing in these Terms constitutes a transfer of any Intellectual Property Rights on the Site Content. You acknowledge and agree that, as between you and us, we own all Intellectual Property Rights on the Site Content, the Platform, the Website and the Mobile Application. </span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">By posting or adding any content onto the Platform, the Website or the Mobile Application you grant us a perpetual, non-exclusive, royalty-free, irrevocable, worldwide and transferable right and licence to use that content in any way (including, without limitation, by reproducing, changing, and communicating the content to the public) and permit us to authorise any other person to do the same thing.</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">The Site Content is protected by copyright and Relaxé reserves all Intellectual Property Rights which may subsist in the Site Content and the Platform.</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">You must not, without Relaxé’ prior written consent which may be withheld at its absolute discretion:</span>

<ol>
<li style="font-weight: 400;"><span style="font-weight: 400;">copy, republish, reproduce, duplicate or extract Site Content;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">redistribute, sell, rent or licence any Site Content; or</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">edit, modify or vary the Site Content.</span></li>
</ol>
</li>
</ol>

<p>&nbsp;</p>
<b>Acceptable Use</b>
<span style="font-weight: 400;">You must not use, or cause the Platform to be used, in any way which:</span>


<ol>
<li style="font-weight: 400;"><span style="font-weight: 400;">breaches any of these Terms;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">infringes Relaxé’ or any third party’s Intellectual Property Rights;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">is fraudulent, illegal or unlawful; or</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">causes impairment of the availability or accessibility of the Platforms.</span></li>
</ol>
<span style="font-weight: 400;">You must not use, or cause this Platform to be used, as a medium which stores, hosts, transmits sends or distributes any material which consists of:</span>


<ol>
<li style="font-weight: 400;"><span style="font-weight: 400;">spyware;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">computer viruses;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Trojans;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">worms;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">keystroke loggers; or</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">any other malicious computer software.</span></li>
</ol>
<span style="font-weight: 400;">You are solely responsible for compliance with any and all laws, rules, regulations, and Tax obligations that may apply to your use of the Platform. In connection with your use of the Platform, you will not assist or enable others to:</span>

<ol>
<li style="font-weight: 400;"><span style="font-weight: 400;">breach or circumvent any applicable laws or regulations, agreements with third-parties, third-party rights, or our Terms, policies or standards;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">use the Patform or collect the contents on the Platform for any commercial or other purposes that are not expressly permitted by these Terms or in a manner that falsely implies Relaxé endorsement, partnership or otherwise misleads others as to your affiliation with Relaxé;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">copy, store or otherwise access or use any information, including personally identifiable information about any other User, contained on the Platform in any way that is inconsistent with Relaxé’ Privacy Policy or these Terms or that otherwise violates the privacy rights of any user of the Platform or third parties;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">use the Platform in connection with the distribution of unsolicited commercial messages;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">contact another User for any purpose other than asking a question related to your Booking, massage provider, or User, including, but not limited to, recruiting or otherwise soliciting any User to join third-party services, applications or websites, without our prior written approval;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">use the Platform to request, make or accept a booking independent of the Platform, to circumvent any Massage Fee or for any other reason;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">request, accept or make any payment for Massage Services outside of the Platform. If you do so, you acknowledge and agree that you:</span>
<ol>
<li style="font-weight: 400;"><span style="font-weight: 400;">would be in breach of these Terms;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">accept all risks and responsibility for such payment made outside of the Platform; and</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">hold Relaxé harmless from any liability for such payment</span></li>
</ol>
</li>
<li style="font-weight: 400;"><span style="font-weight: 400;">discriminate against or harass anyone on the basis of race, national origin, religion, gender, gender identity, physical or mental disability, medical condition, marital status, age or sexual orientation, or otherwise engage in any abusive or disruptive behavior;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">use, display, mirror or frame the Platform or collect the contents on the Platform, or any individual element within the Platform, Relaxé’ name, any Relaxé trademark, logo or other proprietary information, or the layout and design of any page or form contained on a page in the Relaxé platform, without Relaxé’ express written consent;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">dilute, tarnish or otherwise harm the Relaxé brand in any way, including through unauthorized use of the content on the Platform, registering and/or using Relaxé or derivative terms in domain names, trade names, trademarks or other source identifiers, or registering and/or using domains names, trade names, trademarks or other source identifiers that closely imitate or are confusingly similar to Relaxé domains, trademarks, taglines, promotional campaigns;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">use any robots, spider, crawler, scraper or other automated means or processes to access, collect data or other content from or otherwise interact with the Platform for any purpose;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">avoid, bypass, remove, deactivate, impair, descramble, or otherwise attempt to circumvent any technological measure implemented by Relaxé or any massage provider or any other third party to protect the Relaxé platform;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">attempt to decipher, decompile, disassemble or reverse engineer any of the software used to provide the Platform;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">take any action that damages or adversely affects, or could damage or adversely affect the performance or proper functioning of the Platform.</span></li>
</ol>
<p>&nbsp;</p>

<b>Indemnity and Liability</b>
<span style="font-weight: 400;">General Indemnity</span>

<p><span style="font-weight: 400;">You agree to indemnify Relaxé, on demand, against any claim, action, damage, Loss, liability, cost, charge, expense or payment which Relaxé may pay, suffer, incur or are liable for, in relation to any act you do or cause to be done, in breach of these Terms.</span></p>

<span style="font-weight: 400;">General Limitation of Liability</span>

<p><span style="font-weight: 400;">We will not be liable to you in contract, tort or equity in relation to any direct, indirect or consequential loss you incur in relation to the contents or use of or reliance on Site Content or otherwise in connection with the Platform.</span></p>

<span style="font-weight: 400;">Massage Services Liability</span>

<p><span style="font-weight: 400;">To the extent permitted by law, Relaxé will not be responsible and will be excluded from all liability, for any loss or damage whatsoever (including personal injury, loss of life and damage to property) that you or another person may suffer in connection with the offer or supply of (or default in supplying) the Massage Service.</span></p>

<span style="font-weight: 400;">Information Accuracy</span>

<p><span style="font-weight: 400;">You acknowledge and agree that some of the Site Content may be provided by way of blogs or comments made by you or other users of the Platform or Website, and that Relaxé does not accept any liability for the accuracy of such information or your reliance on the same. The Site Content is provided to you as general information only and is not intended to substitute or replace the advice of a duly qualified professional (where applicable).</span></p>

<span style="font-weight: 400;">Acceptance</span>

<span style="font-weight: 400;">By using this Platform, you agree and accept that the indemnity and limitations of liability provided in this clause 16 are reasonable.</span></p>
<p>&nbsp;</p>

<b>Warranties and Representations</b>

<ol>
<li style="font-weight: 400;"><span style="font-weight: 400;">The use of this Platform is at your own risk. The Site Content and everything from the Platform is provided to you on an “as is” and “as available” basis without warranty or condition of any kind.</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">None of Relaxé’ affiliates, directors, officers, employees, agents, contributors, third party content providers or licensors make any express or implied representation or warranty about the Site Content or Platform.</span></li>
</ol>

<p>&nbsp;</p>
<b>General Provisions</b>

<ol>
<li style="font-weight: 400;"><span style="font-weight: 400;">Any provision of, or the application of any provision of these Terms which is prohibited in any jurisdiction is, in that jurisdiction, ineffective only to the extent of that prohibition.</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Any provision of, or the application of any provision of these Terms which is void, illegal or unenforceable in any jurisdiction does not affect the validity, legality or enforceability of that provision in any other jurisdiction or of the remaining provisions in that or any other jurisdiction.</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">If a clause is void, illegal or unenforceable, it may be severed without affecting the enforceability of the other provisions in these Terms.</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">These Terms shall be governed by and construed in accordance with the law for the time being applicable to Lagos State and you agree to submit to the non-exclusive jurisdiction of the Courts of Lagos State.</span></li>
</ol>

</div>`,
  policy: `<div>
  <p><span style="color: #f5f1f1;"> Relaxé acknowledges and respects the privacy of individuals.</span> 
<span style="color: #f5f1f1;">We advise that some information you provide to us, either via our website, e-mail, phone or mail, is ‘personal information’, as defined by the Federal Privacy and Personal Services Information Act 1998 and associated amendments; often referred to as ‘The Privacy Act’. The latter came into force on 21 December 2001.</span></p>
<p><span style="color: #f5f1f1;"> Relaxé seeks to comply with the Privacy Act. In this endeavour, we undertake to provide information to existing and potential clients about how personal information is collected, stored and utilised, as follows: Personal information is collected for the express purpose of executing the booking of your work and for proper administration of the financial transactions.</span></p>
<p><span style="color: #f5f1f1;">The intended recipient of the information you supply  Relaxé, will use it to execute your work, as per your instructions. From time to time, service providers engaged by the Group, for example, software specialists upgrading the databases, may have access to this information, under supervision.</span></p>
<p><span style="color: #f5f1f1;">The provision of information is voluntary, however, if not provided, may inhibit us from executing your transactions with maximum efficiency.</span><br />
<span style="color: #f5f1f1;">Permission to collect, store and use personal information is explicitly sought from individuals and organisations who approach  Relaxé in regard to having work done. Personal information will be collected from you directly when you fill out and/or submit a booking request.</span></p>
<p><span style="color: #f5f1f1;">Your personal information may be used in connection with:</span></p>
<ul>
<li><span style="color: #f5f1f1;">The execution of your work</span></li>
<li><span style="color: #f5f1f1;">The execution of any other transaction you ask  Relaxé to complete on your behalf</span></li>
<li><span style="color: #f5f1f1;">Any insurance claim or proposal that requires disclosure of your personal or sensitive information</span></li>
<li><span style="color: #f5f1f1;">Any information we may provide you in relation to new services offered by  Relaxé.</span></li>
</ul>
<p><span style="color: #f5f1f1;"> Relaxé website may contains links to other sites.  Relaxé make no representations or warranties as to the privacy practices of any site operated by a third party and are not responsible for the privacy policies of such sites.</span></p>
<p><span style="color: #f5f1f1;">From time to time,  Relaxé also uses third-party vendors, including Google, show our ads on sites and mobile apps across the Internet. We and third-party vendors, including Google, may use first-party cookies (such as the Google Analytics cookie) and third-party cookies (such as the DoubleClick cookie) together to inform, optimize, and serve ads based on user’s past visits to our website. Google may use the user’s account data along with cookies to inform, optimize, and serve ads based on user’s past visits to our website. Users can opt out of Google’s use of cookies and login data by visiting</span> <a href="http://www.google.com/settings/ads">Google&#8217;s Ads Settings</a><span style="color: #f5f1f1;">.</span></p>

</div>`
}