/*=========================================================================================
  File Name: store.js
  Description: Vuex store
  ----------------------------------------------------------------------------------------
  Item Name: A.R.T Web Frame
  Version: 1.0.0
  Author: Asheori Reactive Technology
  Author URL: https://asheori.com
==========================================================================================*/


import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'
Vue.use(Vuex)
const vuexLocalStorage = new VuexPersist({
  key: 'relaxe_user', // The key to store the state on in the storage provider.
  storage: window.sessionStorage, // or window.sessionStorage or localForage
  // Function that passes the state and returns the state with only the objects you want to store.
  // reducer: state => state,
  // Function that passes a mutation and lets you decide if it should update the state in localStorage.
  // filter: mutation => (true)
})

import state from "./state"
import getters from "./getters"
import mutations from "./mutations"
import actions from "./actions"



export default new Vuex.Store({
	getters,
	mutations,
	state,
  actions,
  plugins: [vuexLocalStorage.plugin],
	strict: false // process.env.NODE_ENV !== 'production'
})
