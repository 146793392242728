/*=========================================================================================
  File Name: wallet.service.js
  Description: Wallet Services
  ----------------------------------------------------------------------------------------
  Item Name: A.R.T Web Frame
  Version: 1.0.0
  Author: Asheori Reactive Technology
  Author URL: https://asheori.com
==========================================================================================*/
import * as axios from 'axios';
import {
    store
} from "./service.store";

export const WALLET = {
    balance: (user) => {
        const appState = JSON.parse(sessionStorage.getItem("relaxe_user"));
        const HTTPX = axios.create({
            baseURL: `${store.api.config.ledger_endpoint}/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': store.api.config.DEFAULT_AUTHENTICATION_TOKEN,
                'token': appState.authorization ? appState.authorization["token"] : '',
                'identity': appState.authorization ? appState.authorization["identity"] : ''
            }
        });
        return new Promise((resolve, reject) => {
            HTTPX.get(`${store.api.config.WALLET_BALANCE}/${user}`)
                .then(addResponse => {
                    resolve(addResponse.data);
                }).catch(errResponse => {
                    reject(errResponse);
                });
        });
    },

    transactions: (user) => {
        const appState = JSON.parse(sessionStorage.getItem("relaxe_user"));
        const HTTPX = axios.create({
            baseURL: `${store.api.config.ledger_endpoint}/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': store.api.config.DEFAULT_AUTHENTICATION_TOKEN,
                'token': appState.authorization ? appState.authorization["token"] : '',
                'identity': appState.authorization ? appState.authorization["identity"] : ''
            }
        });
        return new Promise((resolve, reject) => {
            HTTPX.get(`${store.api.config.WALLET_TRANSACTION}/${user}`)
                .then(addResponse => {
                    resolve(addResponse.data);
                }).catch(errResponse => {
                    reject(errResponse);
                });
        });
    },

    invoices: (user) => {
        const appState = JSON.parse(sessionStorage.getItem("relaxe_user"));
        const HTTPX = axios.create({
            baseURL: `${store.api.config.ledger_endpoint}/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': store.api.config.DEFAULT_AUTHENTICATION_TOKEN,
                'token': appState.authorization ? appState.authorization["token"] : '',
                'identity': appState.authorization ? appState.authorization["identity"] : ''
            }
        });
        return new Promise((resolve, reject) => {
            HTTPX.get(`${store.api.config.GET_INVOICES}/${user}`)
                .then(addResponse => {
                    resolve(addResponse.data);
                }).catch(errResponse => {
                    reject(errResponse);
                });
        });
    },

    subscription: (user) => {
        const appState = JSON.parse(sessionStorage.getItem("relaxe_user"));
        const HTTPX = axios.create({
            baseURL: `${store.api.config.data_endpoint}/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': store.api.config.DEFAULT_AUTHENTICATION_TOKEN,
                'token': appState.authorization ? appState.authorization["token"] : '',
                'identity': appState.authorization ? appState.authorization["identity"] : ''
            }
        });
        return new Promise((resolve, reject) => {
            HTTPX.get(`${store.api.config.GET_USER_SUBSCRIPTION}/${user}`)
                .then(addResponse => {
                    resolve(addResponse.data);
                }).catch(errResponse => {
                    reject(errResponse);
                });
        });
    },

    pricing: () => {
        const appState = JSON.parse(sessionStorage.getItem("relaxe_user"));
        const HTTPDATA = axios.create({
            baseURL: `${store.api.config.data_endpoint}/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': store.api.config.DEFAULT_AUTHENTICATION_TOKEN,
                'token': appState.authorization ? appState.authorization["token"] : '',
                'identity': appState.authorization ? appState.authorization["identity"] : ''
            }
        });
        return new Promise((resolve, reject) => {
            HTTPDATA.get(store.api.config.GET_PRICING)
                .then(addResponse => {
                    resolve(addResponse.data);
                }).catch(errResponse => {
                    reject(errResponse);
                });
        });
    },

    benefactor: (identifier) => {
        const appState = JSON.parse(sessionStorage.getItem("relaxe_user"));
        const HTTPDATA = axios.create({
            baseURL: `${store.api.config.data_endpoint}/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': store.api.config.DEFAULT_AUTHENTICATION_TOKEN,
                'token': appState.authorization ? appState.authorization["token"] : '',
                'identity': appState.authorization ? appState.authorization["identity"] : ''
            }
        });
        return new Promise((resolve, reject) => {
            HTTPDATA.get(`${store.api.config.GET_BENEFACTOR}/${identifier}`)
                .then(addResponse => {
                    resolve(addResponse.data);
                }).catch(errResponse => {
                    reject(errResponse);
                });
        });
    },

    generateInvoice: (payload) => {
        const appState = JSON.parse(sessionStorage.getItem("relaxe_user"));
        const HTTPX = axios.create({
            baseURL: `${store.api.config.ledger_endpoint}/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': store.api.config.DEFAULT_AUTHENTICATION_TOKEN,
                'token': appState.authorization ? appState.authorization["token"] : '',
                'identity': appState.authorization ? appState.authorization["identity"] : ''
            }
        });
        return new Promise((resolve, reject) => {
            HTTPX.post(`${store.api.config.GENERATE_INVOICE}`, payload)
                .then(addResponse => {
                    resolve(addResponse.data);
                }).catch(errResponse => {
                    reject(errResponse);
                });
        });
    },

    makePayment: (payload) => {
        const appState = JSON.parse(sessionStorage.getItem("relaxe_user"));
        const HTTPX = axios.create({
            baseURL: `${store.api.config.ledger_endpoint}/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': store.api.config.DEFAULT_AUTHENTICATION_TOKEN,
                'token': appState.authorization ? appState.authorization["token"] : '',
                'identity': appState.authorization ? appState.authorization["identity"] : ''
            }
        });
        return new Promise((resolve, reject) => {
            HTTPX.post(store.api.config.TRANSFER_FUND, payload)
                .then(addResponse => {
                    resolve(addResponse.data);
                }).catch(errResponse => {
                    reject(errResponse);
                });
        });
    },

    verifyPayment: (transaction) => {
        const HTTPX = axios.create({
            baseURL: `${store.api.config.public_endpoint}/`,
            headers: {
                'Content-Type': 'application/json',
            }
        });
        return new Promise((resolve, reject) => {
            HTTPX.get(`${store.api.config.VERIFY_PAYMENT}/${transaction}`)
                .then(addResponse => {
                    resolve(addResponse.data);
                }).catch(errResponse => {
                    reject(errResponse);
                });
        });
    }
}
