<template>
  <f7-page ptr @ptr:refresh="refresh">
    <f7-progressbar v-if="loading" infinite color="multi"></f7-progressbar>
    <f7-navbar title="My Gallery">
      <f7-nav-right>
        <f7-link
          @click="$refs.galleryActionGroup.open()"
          icon-ios="f7:add"
          icon-aurora="f7:add"
          icon-md="f7:add"
          prevent-router
          :animate="true"
        >ADD</f7-link>
      </f7-nav-right>
    </f7-navbar>
    <!-- No Gallery -->
    <div v-if="gallery.length === 0" class="p-20 text-center mt-40">
      <img :src="staticImage" alt=":-(" class="asset-img" />
      <div
        class="p-20 text-center text-white"
      >No photo in your gallery. Click the (+) sign top right to add photo(s) to your gallery.</div>
    </div>

    <!-- Gallery -->
    <f7-card
      v-for="(item, index) in gallery"
      :key="index"
      :style="{borderRadius: '10px', marginBottom: '20px'}"
    >
      <div
        @click="imageOptionPopup(item)"
        :style="{background: `url(${item.photo.url ? item.photo.url : defaultAvatar}) no-repeat center top`, 'background-size': 'cover', height: '480px', borderRadius: '10px'}"
      ></div>
    </f7-card>

    <!-- Gallery Action Group -->
    <f7-actions ref="galleryActionGroup">
      <f7-actions-group>
        <f7-actions-label>Select image source</f7-actions-label>
        <f7-actions-button @click="openCamera(1)">
          <f7-icon f7="camera" slot="media"></f7-icon>Phone Camera
        </f7-actions-button>
        <f7-actions-button @click="openCamera(0)">
          <f7-icon f7="images" slot="media"></f7-icon>Phone Gallery
        </f7-actions-button>
        <f7-actions-button color="red">
          <f7-icon f7="arrow_left" slot="media"></f7-icon>Cancel
        </f7-actions-button>
      </f7-actions-group>
    </f7-actions>
    <!-- /Gallery Action Group -->

    <!-- Image Action Group -->
    <f7-actions ref="imageActionGroup">
      <f7-actions-group>
        <f7-actions-label>Image Action</f7-actions-label>
        <f7-actions-button @click="setAsAvatar()">
          <f7-icon f7="person_round" slot="media"></f7-icon>Use as avatar
        </f7-actions-button>
        <f7-actions-button color="red" @click="removeImageFromGallery()">
          <f7-icon f7="trash" color="red" slot="media"></f7-icon>Delete photo
        </f7-actions-button>
        <f7-actions-button color="red">
          <f7-icon f7="arrow_left" slot="media"></f7-icon>Cancel
        </f7-actions-button>
      </f7-actions-group>
    </f7-actions>
  </f7-page>
</template>
<script>
import { mapGetters } from "vuex";
import Vue from "vue";
export default {
  data() {
    return {
      loading: false,
      payload: {},
      defaultAvatar: require("@/assets/icons/massage.png"),
      staticImage: require("@/assets/icons/gallery.png")
    };
  },
  methods: {
    refresh(event, done) {
      this.loading = true;
      // Update User Gallery State
      this.$store
        .dispatch("getGallery", {
          data: this.candidateProfile.personal.identifier
        })
        .then(() => {
          this.loading = false;
        });

      setTimeout(() => {
        done();
      }, 500);
    },

    openCamera(source) {
      if (this.gallery.length >= 6) {
        this.localNotify(
          "Gallery Full",
          `You have reached your max gallery size.`,
          "primary-theme"
        );
      } else {
        this.localNotify(
          "Notification",
          `Please download Relaxe mobile app to update your profile photo.`,
          "default-theme"
        );
      }
    },

    imageOptionPopup(item) {
      this.payload = item;
      this.$refs.imageActionGroup.open();
    },

    setAsAvatar() {
      this.loading = true;
      // Update User Avatar
      this.$store
        .dispatch("setAsAvatar", {
          data: this.payload
        })
        .then(() => {
          this.loading = false;
          this.localNotify(
            "Avatar Updated",
            `Photo is set as profile avatar!`,
            "success-theme"
          );
        });
    },

    removeImageFromGallery() {
      this.loading = true;
      // Remove image from gallary
      this.$store
        .dispatch("removeImageFromGallery", {
          data: this.payload
        })
        .then(() => {
          this.loading = false;
          this.localNotify(
            "Gallery Updated",
            `Photo deleted successfully!`,
            "success-theme"
          );
        });
    },

    localNotify(subtitle, text, colorTheme) {
      const self = this;
      const app = self.$f7;
      app.data.notification.show(app, subtitle, text, colorTheme);
    }
  },
  computed: {
    ...mapGetters(["candidateProfile", "gallery"])
  }
};
</script>