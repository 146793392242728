/* eslint-disable no-unused-vars */
/*=========================================================================================
  File Name: actions.js
  Description: Vuex Store - actions
  ----------------------------------------------------------------------------------------
  Item Name: A.R.T Web Frame
  Version: 1.0.0
  Author: Asheori Reactive Technology
  Author URL: https://asheori.com
==========================================================================================*/
import { PROFILE } from "@/services/profile.service";
import { BOOKING } from "@/services/booking.service";
import { PROVIDER } from "@/services/provider.service";
import { WALLET } from "@/services/wallet.service";
import { GALLERY } from "@/services/gallery.service";
import { store } from "@/services/service.store";

const actions = {


	// ////////////////////////////////////////////
	// CANDIDADATE PROFILE COMPONENT
	// ////////////////////////////////////////////	

	// Get Wallet Balance for User
	getWalletBalance({
		commit
	}, payload) {
		return new Promise((resolve, reject) => {
			WALLET.balance(payload.data.identifier)
				.then(balanceResponse => {
					const balance = parseFloat((balanceResponse['result']['balance'] / 100).toFixed(2));
					commit('UPDATE_USER_WALLET', { data: balance });
					resolve();
				}).catch(responseError => reject(responseError));
		});
	},

	// Get Wallet Transactions
	getWalletTransactions({
		commit
	}, payload) {
		return new Promise((resolve, reject) => {
			WALLET.transactions(payload.data.identifier)
				.then(tranxResponse => {
					commit('UPDATE_USER_WALLET_TRANSACTIONS', { data: tranxResponse['result'] });
					resolve();
				}).catch(responseError => reject(responseError));
		});
	},

	// Update Notification Settings
	updateNotificationSettings({
		commit
	}, payload) {
		return new Promise((resolve, reject) => {
			PROFILE.updateResource(payload.data, store.api.config.UPDATE_NOTIFICATION_SETTINGS)
				.then(response => {
					if (response["success"]) {
						commit('UPDATE_NOTIFICATION_SETTINGS', payload);
						resolve();
					}
					else {
						reject("Service not rendered")
					}
				}).catch(responseError => reject(responseError));
		});
	},

	// Update Provider Status
	updateProviderStatus({
		commit
	}, payload) {
		return new Promise((resolve, reject) => {
			PROFILE.updateResource(payload.data, store.api.config.UPDATE_PROVIDER_STATUS)
				.then(response => {
					if (response["success"]) {
						commit('UPDATE_PROVIDER_STATUS', payload);
						resolve();
					}
					else {
						reject("Service not rendered")
					}
				}).catch(responseError => reject(responseError));
		});
	},

	// Update Online Status
	updateOnlineStatus({
		commit
	}, payload) {
		return new Promise((resolve, reject) => {
			PROFILE.updateResource(payload.data, store.api.config.UPDATE_ONLINE_STATUS)
				.then(response => {
					if (response["success"]) {
						commit('UPDATE_ONLINE_STATUS', payload);
						resolve();
					}
					else {
						reject("Service not rendered")
					}
				}).catch(responseError => reject(responseError));
		});
	},


	// Update Verify Email Status
	updateUserEmailVerifyStatus({
		commit
	}, payload) {
		return new Promise((resolve, reject) => {
			PROFILE.updateResource(payload.data, store.api.config.UPDATE_VERIFY_EMAIL)
				.then(response => {
					if (response["success"]) {
						commit('UPDATE_USER_VERIFY_EMAIL', payload);
						resolve();
					}
					else {
						reject("Service not rendered")
					}
				}).catch(responseError => reject(responseError));
		});
	},

	// Update Verify Mobile Status
	updateUserMobileVerifyStatus({
		commit
	}, payload) {
		return new Promise((resolve, reject) => {
			PROFILE.updateResource(payload.data, store.api.config.UPDATE_VERIFY_MOBILE)
				.then(response => {
					if (response["success"]) {
						commit('UPDATE_USER_VERIFY_MOBILE', payload);
						resolve();
					}
					else {
						reject("Service not rendered")
					}
				}).catch(responseError => reject(responseError));
		});
	},

	// Update Interests
	updateUserInterests({
		commit
	}, payload) {
		return new Promise((resolve, reject) => {
			PROFILE.updateResource(payload.data, store.api.config.UPDATE_INTERESTS)
				.then(response => {
					if (response["success"]) {
						commit('UPDATE_USER_INTERESTS', payload);
						resolve();
					}
					else {
						reject("Service not rendered")
					}
				}).catch(responseError => reject(responseError));
		});
	},

	// Update Preferences (Specialty)
	updateUserPreferences({
		commit
	}, payload) {
		return new Promise((resolve, reject) => {
			PROFILE.updateResource(payload.data, store.api.config.UPDATE_PREFERENCES)
				.then(response => {
					if (response["success"]) {
						commit('UPDATE_USER_PREFERENCES', payload);
						resolve();
					}
					else {
						reject("Service not rendered")
					}
				}).catch(responseError => reject(responseError));
		});
	},

	// Update Profile Information
	updateUserProfile({
		commit
	}, payload) {
		return new Promise((resolve, reject) => {
			PROFILE.updateResource(payload.data, store.api.config.UPDATE_PERSONAL)
				.then(response => {
					if (response["success"]) {
						commit('UPDATE_USER_PROFILE', { data: response["result"] });
						resolve();
					}
					else {
						reject("Service not rendered")
					}
				}).catch(responseError => reject(responseError));
		});
	},

	// Update User General Settings Information
	updateUserGeneralSettings({
		commit
	}, payload) {
		return new Promise((resolve, reject) => {
			PROFILE.updateResource(payload.data, store.api.config.UPDATE_GENERAL_SETTINGS)
				.then(response => {
					if (response["success"]) {
						commit('UPDATE_USER_PROFILE', { data: response["result"] });
						resolve();
					}
					else {
						reject("Service not rendered")
					}
				}).catch(responseError => reject(responseError));
		});
	},

	// Subscription Settings
	getUserSubscription({
		commit
	}, payload) {
		return new Promise((resolve, reject) => {
			WALLET.subscription(payload.data)
				.then(response => {
					if (response["success"]) {
						commit('UPDATE_USER_SUBSCRIPTION', { data: response["result"] });
					}
					resolve();
				}).catch(responseError => reject(responseError));
		});
	},

	getSubscriptionPricing({
		commit
	}, payload) {
		return new Promise((resolve, reject) => {
			WALLET.pricing()
				.then(response => {
					if (response["success"]) {
						commit('UPDATE_SUBSCRIPTION_PRICING', { data: response["result"] });
					}
					resolve();
				}).catch(responseError => reject(responseError));
		});
	},

	createUserBooking({
		commit
	}, payload) {
		return new Promise((resolve, reject) => {
			payload.data.confirm_status = false;
			BOOKING.create(payload.data)
				.then(response => {
					if (response["success"]) {
						commit('ADD_USER_BOOKING', { data: response["result"] });
						resolve();
					}
					else {
						reject("Service not rendered")
					}
				}).catch(responseError => reject(responseError));
		});
	},

	updateUserBooking({
		commit
	}, payload) {
		return new Promise((resolve, reject) => {
			payload.data.confirm_status = false;
			BOOKING.update(payload.data)
				.then(response => {
					if (response["success"]) {
						commit('UPDATE_USER_BOOKING', { data: response["result"] });
						resolve();
					}
					else {
						reject("Service not rendered")
					}
				}).catch(responseError => reject(responseError));
		});
	},

	getUserBookings({
		commit
	}, payload) {
		return new Promise((resolve, reject) => {
			BOOKING.userBookings(payload.data)
				.then(response => {
					if (response["success"]) {
						commit('USER_BOOKINGS', { data: response["result"] });
					}
					resolve();
				}).catch(responseError => reject(responseError));
		});
	},

	getProviderBookings({
		commit
	}, payload) {
		return new Promise((resolve, reject) => {
			BOOKING.providerBookings(payload.data)
				.then(response => {
					if (response["success"]) {
						commit('PROVIDER_BOOKINGS', { data: response["result"] });
					}
					resolve();
				}).catch(responseError => reject(responseError));
		});
	},

	confirmBooking({
		commit
	}, payload) {
		return new Promise((resolve, reject) => {
			BOOKING.confirm(payload.data)
				.then(response => {
					if (response["success"]) {
						commit('CONFIRM_USER_BOOKING', payload);
						resolve();
					}
					else {
						reject("Service not rendered")
					}
				}).catch(responseError => reject(responseError));
		});
	},

	
	checkinBooking({
		commit
	}, payload) {
		return new Promise((resolve, reject) => {
			BOOKING.checkin(payload.data)
				.then(response => {
					if (response["success"]) {
						commit('CHECKIN_BOOKING', payload);
						resolve();
					}
					else {
						reject("Service not rendered")
					}
				}).catch(responseError => reject(responseError));
		});
	},

	checkoutBooking({
		commit
	}, payload) {
		return new Promise((resolve, reject) => {
			BOOKING.checkout(payload.data)
				.then(response => {
					if (response["success"]) {
						commit('CHECKOUT_BOOKING', payload);
						resolve();
					}
					else {
						reject("Service not rendered")
					}
				}).catch(responseError => reject(responseError));
		});
	},

	burnBooking({
		commit
	}, payload) {
		return new Promise((resolve, reject) => {
			BOOKING.burn(payload.data)
				.then(response => {
					if (response["success"]) {
						commit('BURN_BOOKING', payload);
						resolve();
					}
					else {
						reject("Service not rendered")
					}
				}).catch(responseError => reject(responseError));
		});
	},

	cancelBooking({
		commit
	}, payload) {
		return new Promise((resolve, reject) => {
			BOOKING.burn(payload.data)
				.then(response => {
					if (response["success"]) {
						commit('CANCEL_BOOKING', payload);
						resolve();
					}
					else {
						reject("Service not rendered")
					}
				}).catch(responseError => reject(responseError));
		});
	},

	getProviders({
		commit
	}, payload) {
		return new Promise((resolve, reject) => {
			PROVIDER.all()
				.then(response => {
					if (response["success"]) {
						commit('PROVIDERS', { data: response["result"] });
						resolve();
					}
					else {
						reject("Service not rendered")
					}
				}).catch(responseError => reject(responseError));
		});
	},

	getFilteredProviders({
		commit
	}, payload) {
		return new Promise((resolve, reject) => {
			PROVIDER.search(payload.data)
				.then(response => {
					if (response["success"]) {
						commit('PROVIDERS', { data: response["result"] });
						resolve();
					}
					else {
						reject("Service not rendered")
					}
				}).catch(responseError => reject(responseError));
		});
	},

	// Add to User Gallery
	addUserGallery({
		commit
	}, payload) {
		return new Promise((resolve, reject) => {
			payload.data.confirm_status = false;
			GALLERY.create(payload.data)
				.then(response => {
					if (response["success"]) {
						commit('ADD_USER_GALLERY', { data: response["result"] });
						resolve();
					}
					else {
						reject("Service not rendered")
					}
				}).catch(responseError => reject(responseError));
		});
	},

	// Get User Gallery
	getGallery({
		commit
	}, payload) {
		return new Promise((resolve, reject) => {
			GALLERY.all(payload.data)
				.then(response => {
					if (response["success"]) {
						commit('GALLERY', { data: response["result"] });
						resolve();
					}
					else {
						reject("Service not rendered")
					}
				}).catch(responseError => reject(responseError));
		});
	},

	// Set User Avatar From Gallery
	setAsAvatar({
		commit
	}, payload) {
		return new Promise((resolve, reject) => {
			GALLERY.setAsAvatar(payload.data)
				.then(response => {
					if (response["success"]) {
						commit('UPDATE_USER_PHOTO', { data: payload.data.photo });
						resolve();
					}
					else {
						reject("Service not rendered")
					}
				}).catch(responseError => reject(responseError));
		});
	},


	// Remove Image from User Gallery
	removeImageFromGallery({
		commit
	}, payload) {
		return new Promise((resolve, reject) => {
			GALLERY.remove(payload.data)
				.then(response => {
					if (response["success"]) {
						commit('REMOVE_IMAGE_FROM_GALLERY', payload);
						resolve();
					}
					else {
						reject("Service not rendered")
					}
				}).catch(responseError => reject(responseError));
		});
	},

	// Update  Financial Information
	updateUserFinancial({
		commit
	}, payload) {
		return new Promise((resolve, reject) => {
			PROFILE.updateResource(payload.data, store.api.config.UPDATE_FINANCIAL)
				.then(response => {
					if (response["success"]) {
						commit('UPDATE_USER_FINANCIAL', payload);
						resolve();
					}
					else {
						reject("Service not rendered")
					}
				}).catch(responseError => reject(responseError));
		});
	},

	// Update Security Information
	updateUserSecurity({
		commit
	}, payload) {
		return new Promise((resolve, reject) => {
			PROFILE.updateSecurityResource(payload.data, store.api.config.UPDATE_SECURITY)
				.then(response => {
					if (response["success"]) {
						// commit('UPDATE_USER_SECURITY', payload);
						resolve();
					}
					else {
						reject("Service not rendered")
					}
				}).catch(responseError => reject(responseError));
		});
	},

	// Reset Security Information
	resetUserSecurity({
		commit
	}, payload) {
		return new Promise((resolve, reject) => {
			PROFILE.updateSecurityResource(payload.data, store.api.config.RESET_SECURITY)
				.then(response => {
					if (response["success"]) {
						// commit('UPDATE_USER_SECURITY', payload);
						resolve();
					}
					else {
						reject("Service not rendered")
					}
				}).catch(responseError => reject(responseError));
		});
	},

	// Update Profile Photo
	updateUserProfilePhoto({
		commit
	}, payload) {
		return new Promise((resolve, reject) => {
			PROFILE.updateResource(payload.data, store.api.config.UPDATE_USER_PHOTO)
				.then(response => {
					if (response["success"]) {
						commit('UPDATE_USER_PHOTO', { data: response["result"]["photo"] });
						resolve();
					}
					else {
						reject("Service not rendered")
					}
				}).catch(responseError => reject(responseError));
		});
	},


	// Upload Profile Photo
	uploadUserProfilePhoto({
		commit
	}, payload) {
		return new Promise((resolve, reject) => {
			PROFILE.uploadCoverPhoto(payload.data.fileName, payload.data.file)
				.then(response => {
					if (response["success"]) {
						commit('UPDATE_USER_PHOTO', payload);
						resolve();
					}
					else {
						reject("Service not rendered")
					}
				}).catch(responseError => reject(responseError));
		});
	},

	// Remove Interest
	removeInterest({
		commit
	}, payload) {
		setTimeout(() => {
			commit('REMOVE_INTEREST', payload)
		}, 300);
	},

	// Remove Preference (Specialty)
	removePreference({
		commit
	}, payload) {
		setTimeout(() => {
			commit('REMOVE_PREFERENCE', payload)
		}, 3000);
	},

}

export default actions