/*=========================================================================================
  File Name: booking.service.js
  Description: Booking Services
  ----------------------------------------------------------------------------------------
  Item Name: A.R.T Web Frame
  Version: 1.0.0
  Author: Asheori Reactive Technology
  Author URL: https://asheori.com
==========================================================================================*/
import * as axios from 'axios';
import {
    store
} from "./service.store";

export const BOOKING = {
    create: (payload) => {
        const appState = JSON.parse(sessionStorage.getItem("relaxe_user"));
        const HTTPX = axios.create({
            baseURL: `${store.api.config.data_endpoint}/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': store.api.config.DEFAULT_AUTHENTICATION_TOKEN,
                'token': appState.authorization ? appState.authorization["token"] : '',
                'identity': appState.authorization ? appState.authorization["identity"] : ''
            }
        });
        return new Promise((resolve, reject) => {
            HTTPX.post(store.api.config.CREATE_BOOKING, payload)
                .then(addResponse => {
                    resolve(addResponse.data);
                }).catch(errResponse => {
                    reject(errResponse);
                });
        });
    },

    update: (payload) => {
        const appState = JSON.parse(sessionStorage.getItem("relaxe_user"));
        const HTTPX = axios.create({
            baseURL: `${store.api.config.data_endpoint}/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': store.api.config.DEFAULT_AUTHENTICATION_TOKEN,
                'token': appState.authorization ? appState.authorization["token"] : '',
                'identity': appState.authorization ? appState.authorization["identity"] : ''
            }
        });
        return new Promise((resolve, reject) => {
            HTTPX.patch(store.api.config.UPDATE_BOOKING, payload)
                .then(addResponse => {
                    resolve(addResponse.data);
                }).catch(errResponse => {
                    reject(errResponse);
                });
        });
    },

    userBookings: (user) => {
        const appState = JSON.parse(sessionStorage.getItem("relaxe_user"));
        const HTTPX = axios.create({
            baseURL: `${store.api.config.data_endpoint}/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': store.api.config.DEFAULT_AUTHENTICATION_TOKEN,
                'token': appState.authorization ? appState.authorization["token"] : '',
                'identity': appState.authorization ? appState.authorization["identity"] : ''
            }
        });
        return new Promise((resolve, reject) => {
            HTTPX.get(`${store.api.config.GET_USER_BOOKING}/${user}`)
                .then(addResponse => {
                    resolve(addResponse.data);
                }).catch(errResponse => {
                    reject(errResponse);
                });
        });
    },

    providerBookings: (provider) => {
        const appState = JSON.parse(sessionStorage.getItem("relaxe_user"));
        const HTTPDATA = axios.create({
            baseURL: `${store.api.config.data_endpoint}/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': store.api.config.DEFAULT_AUTHENTICATION_TOKEN,
                'token': appState.authorization ? appState.authorization["token"] : '',
                'identity': appState.authorization ? appState.authorization["identity"] : ''
            }
        });
        return new Promise((resolve, reject) => {
            HTTPDATA.get(`${store.api.config.GET_PROVIDER_BOOKING}/${provider}`)
                .then(addResponse => {
                    resolve(addResponse.data);
                }).catch(errResponse => {
                    reject(errResponse);
                });
        });
    },

    confirm: (payload) => {
        const appState = JSON.parse(sessionStorage.getItem("relaxe_user"));
        const HTTPDATA = axios.create({
            baseURL: `${store.api.config.data_endpoint}/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': store.api.config.DEFAULT_AUTHENTICATION_TOKEN,
                'token': appState.authorization ? appState.authorization["token"] : '',
                'identity': appState.authorization ? appState.authorization["identity"] : ''
            }
        });
        return new Promise((resolve, reject) => {
            HTTPDATA.patch(store.api.config.CONFIRM_BOOKING, payload)
                .then(addResponse => {
                    resolve(addResponse.data);
                }).catch(errResponse => {
                    reject(errResponse);
                });
        });
    },
   


    checkin: (payload) => {
        const appState = JSON.parse(sessionStorage.getItem("relaxe_user"));
        const HTTPDATA = axios.create({
            baseURL: `${store.api.config.data_endpoint}/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': store.api.config.DEFAULT_AUTHENTICATION_TOKEN,
                'token': appState.authorization ? appState.authorization["token"] : '',
                'identity': appState.authorization ? appState.authorization["identity"] : ''
            }
        });
        return new Promise((resolve, reject) => {
            HTTPDATA.patch(store.api.config.CHECKIN_BOOKING, payload)
                .then(addResponse => {
                    resolve(addResponse.data);
                }).catch(errResponse => {
                    reject(errResponse);
                });
        });
    },


    checkout: (payload) => {
        const appState = JSON.parse(sessionStorage.getItem("relaxe_user"));
        const HTTPDATA = axios.create({
            baseURL: `${store.api.config.data_endpoint}/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': store.api.config.DEFAULT_AUTHENTICATION_TOKEN,
                'token': appState.authorization ? appState.authorization["token"] : '',
                'identity': appState.authorization ? appState.authorization["identity"] : ''
            }
        });
        return new Promise((resolve, reject) => {
            HTTPDATA.patch(store.api.config.CHECKOUT_BOOKING, payload)
                .then(addResponse => {
                    resolve(addResponse.data);
                }).catch(errResponse => {
                    reject(errResponse);
                });
        });
    },
   

    burn: (payload) => {
        const appState = JSON.parse(sessionStorage.getItem("relaxe_user"));
        const HTTPDATA = axios.create({
            baseURL: `${store.api.config.data_endpoint}/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': store.api.config.DEFAULT_AUTHENTICATION_TOKEN,
                'token': appState.authorization ? appState.authorization["token"] : '',
                'identity': appState.authorization ? appState.authorization["identity"] : ''
            }
        });
        return new Promise((resolve, reject) => {
            HTTPDATA.patch(store.api.config.BURN_BOOKING, payload)
                .then(addResponse => {
                    resolve(addResponse.data);
                }).catch(errResponse => {
                    reject(errResponse);
                });
        });
    },

}